import { black, white } from '@pelotoncycle/design-system';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import type { ExtLinkEnvState, ExtLinkEnv } from '@peloton/external-links';
import { toHref, getExtLinkEnv } from '@peloton/external-links';
import { useOneTrust } from '@peloton/onetrust/useOneTrust';
import { media } from '@peloton/styles';
import type { AccountKeyValue } from '@account/copy';
import { AccountCopy } from '@account/copy';
import { spaces } from '@account/styles';
import { b5, b6, reg } from '@acme-ui/typography';
import { L4 } from '@ecomm/links';
import type { FooterProps } from './static';
import { FooterData } from './static';

type Props = FooterProps & { extLinkEnv: ExtLinkEnv };

const PageFooter: React.FC<React.PropsWithChildren<Props>> = ({ extLinkEnv, links }) => {
  const {
    isReady: isOneTrustReady,
    toggleInfoDisplay,
    isDoNotSellEnabled,
    doNotSellLink,
  } = useOneTrust();

  const toCookieLink = (id: AccountKeyValue) => (
    <StyledL4 onClick={() => toggleInfoDisplay()}>
      <AccountCopy id={id} />
    </StyledL4>
  );

  const toDoNotSellLink = (id: AccountKeyValue) => (
    <StyledL4 href={doNotSellLink} target="_blank" rel="noopener">
      <AccountCopy id={id} />
    </StyledL4>
  );

  return (
    <FooterBar>
      <FooterContent>
        <LinkSection>
          {links.map(link => (
            <StyledL4
              key={link.key}
              href={toHref(link.href, extLinkEnv)}
              target="_blank"
              rel="noopener"
            >
              <AccountCopy id={link.key} />
            </StyledL4>
          ))}
          {isOneTrustReady && toCookieLink('footer_links_cookieSettings')}
          {isDoNotSellEnabled && toDoNotSellLink('footer_links_doNotSellShare')}
        </LinkSection>
        <AccountCopy id="footer_copyrightText" />
      </FooterContent>
    </FooterBar>
  );
};

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${spaces.small}px;
  ${media.desktop`
    width: 384px;
    flex-direction: row;
  `}
`;

const FooterBar = styled.footer`
  ${b6}
  background-color: ${black};
  height: 280px;
  color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.desktop`
    height: 127px
  `}
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 232px;
  ${media.desktop`
    width: auto;
  `}
`;

const StyledL4 = styled(L4)`
  ${b5}
  ${reg}
  line-height: 1;
  padding: 5px 5px;
  ${media.desktop`
    padding: 0px 5px;
    border-right: 1px solid white;
    :last-child {
      border: none;
    `}
  }`;

const mapStateToProps = (state: ExtLinkEnvState) => ({
  ...FooterData,
  extLinkEnv: getExtLinkEnv(state),
});

export default compose(connect(mapStateToProps))(PageFooter);
