const hasIosUserAgent = () => /iPad|iPhone|iPod/i.test(navigator.userAgent);

const hasIosPlatform = () => /iPad|iPhone|iPod/i.test(navigator.platform);

const hasIosFeatures = () =>
  'ontouchstart' in window ||
  navigator['MaxTouchPoints'] > 0 ||
  navigator.maxTouchPoints > 0;

/**
 * @description Device might be on iOS (iPhone Operating System),
 * if it has iOS user agent, iOS platform, and supports some of iOS specific features
 */
export const isIosDevice = () =>
  hasIosUserAgent() && hasIosPlatform() && hasIosFeatures();

export const isMobile = () => {
  return (
    /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    hasIosUserAgent() ||
    /Android|webOS|BlackBerry/i.test(navigator.platform) ||
    hasIosPlatform()
  );
};

// This covers scenarios when Request Desktop Website is used and iPadPro
export const isNonDesktopIOSDevice = () => {
  return (
    navigator.userAgent.toLowerCase().includes('mac') &&
    (navigator.maxTouchPoints > 1 || 'ontouchstart' in window)
  );
};
