import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { useOauth } from '@peloton/auth';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import isTokenExpired from '@ecomm/api/isTokenExpired';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import useTokenMismatchHandler from './useTokenMismatchHandler';

export const addAuthorizationHeader = (
  config: AxiosRequestConfig,
  token: string | null,
) => {
  config.headers['Authorization'] = `Bearer ${token}`;
};

const useAuthorizationHeader = (client: AxiosInstance) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useOauth();
  const [clientHeadersReady, setClientHeadersReady] = useState<boolean>(false);
  const cfxToken = window.CFXHOME && window.CFXHOME.getAccessToken();
  const [checkoutAccessToken, , removeCheckoutToken] = useLocalStorage(
    CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
    null,
  );

  useTokenMismatchHandler(client);

  useEffect(() => {
    if (isTokenExpired(checkoutAccessToken)) {
      removeCheckoutToken();
    }
    let requestInterceptor: number;

    const addInterceptor = (token?: string) => {
      requestInterceptor = client.interceptors.request.use(async config => {
        if (token) {
          addAuthorizationHeader(config, token);
          return config;
        }

        if (isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
              addAuthorizationHeader(config, accessToken);
            }
          } catch {
            // There was an error getting the access token (probably either because of a timeout or the user not being logged in)
            // In either case, we can let this request go through without an authorization header
          }
        } else {
          if (checkoutAccessToken) {
            addAuthorizationHeader(config, checkoutAccessToken);
          }
        }

        return config;
      });
      setClientHeadersReady(true);
    };

    if (cfxToken) {
      if (!clientHeadersReady) {
        addInterceptor(cfxToken);
      }
      return;
    }

    if (isLoading) {
      return;
    }

    addInterceptor();

    return () => {
      client.interceptors.request.eject(requestInterceptor);
      setClientHeadersReady(false);
    };
  }, [
    client.interceptors.request,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    checkoutAccessToken,
  ]);

  return clientHeadersReady;
};

export default useAuthorizationHeader;
