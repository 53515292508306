import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ie11 } from '@peloton/styles';
import HeaderNavProvider from './HeaderNavProvider';
import HeaderNavView from './HeaderNavView';

const withHeaderNavProvider = <T extends object>(
  Component: React.FC<React.PropsWithChildren<T>>,
) => (props: T) => <HeaderNavProvider>{<Component {...props} />}</HeaderNavProvider>;

const MainHeaderNav = styled(withHeaderNavProvider(HeaderNavView))`
  background-color: ${brand.darkest};
  position: sticky;

  ${ie11`
    position: relative;
  `}
`;

export { MainHeaderNav };
