import type { ReactNode } from 'react';
import React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

type HeaderContextData = {
  isShowingMembershipMenu: boolean;
  setIsShowingMembershipMenu: React.Dispatch<React.SetStateAction<boolean>>;
  hideMenu: () => void;
};

const initialState = {
  isShowingMembershipMenu: false,
  setIsShowingMembershipMenu: () => {},
  hideMenu: () => {},
};

export const HeaderNavContext = createContext<HeaderContextData>(initialState);

type Props = {
  children: ReactNode;
};

const HeaderNavProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [isShowingMembershipMenu, setIsShowingMembershipMenu] = useState<boolean>(false);

  useEffect(() => {
    const resetMenu = () => {
      setIsShowingMembershipMenu(false);
    };

    window.addEventListener('orientationchange', resetMenu);

    return () => {
      window.removeEventListener('orientationchange', resetMenu);
    };
  }, []);

  const hideMenu = () => {
    setIsShowingMembershipMenu(false);
  };

  const value = useMemo(
    () => ({
      isShowingMembershipMenu,
      setIsShowingMembershipMenu,
      hideMenu,
    }),
    [isShowingMembershipMenu],
  );

  return <HeaderNavContext.Provider value={value}>{children}</HeaderNavContext.Provider>;
};

export default HeaderNavProvider;
