import type { CacheResolver } from 'apollo-boost/lib/index';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost/lib/index';
import 'whatwg-fetch';
import { keys } from 'ramda';
import type { ClientConfig, QueryMap } from './config';
import dataIdFromObject from './dataIdFromObject';

// Since we don't have a good way of unwrapping/normalizing/querying Contentful Ids (and are instead using `key`)
// toQuery/toResolver tell Apollo *how* to store the data in-cache, and, by extensino, make it possible to
// query data by key
//
// More info/cookbook: https://www.apollographql.com/docs/react/advanced/caching/#cache-redirects-with-cacheredirects
const toQuery = (queryMap: QueryMap) =>
  keys(queryMap).reduce((query, key) => {
    query[key] = toResolver(queryMap[key]);
    return query;
  }, {});

const toResolver = (__typename: string): CacheResolver => (_, { id }, { getCacheKey }) =>
  getCacheKey({ __typename, key: id });

export type CopyFile = {
  data: Record<string, { items: Record<string, KeyValueType>[] }>;
};
type KeyValueType = { key: string; value: string; __typename: string };

/**
 * returns a new Apollo Client, hydrated with localized strings
 */
export const toClient = (config: ClientConfig) => ({ data }: CopyFile) => {
  const link = new HttpLink({
    uri: config.url,
    headers: {
      Authorization: `Bearer ${config.token}`,
    },
  });

  const cache = new InMemoryCache({
    dataIdFromObject,
    cacheRedirects: {
      Query: toQuery(config.queryMap),
    },
  });

  const client = new ApolloClient({
    cache,
    link,
  });

  client.writeQuery({
    query: config.queryDocument,
    data,
  });

  if (config.hydrateOnLoad) {
    client.query({
      query: config.queryDocument,
      variables: config.queryVariables,
    });
  }
  if (process.env.NOTE_ENV !== 'production') {
    (window as any).__printCopy = () => client.extract();
  }
  return client;
};
