import React from 'react';
import styled from 'styled-components';
import { Footer } from '@account/footer';
import { MainHeaderNav } from '../page-header';
interface Props {
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
}

const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  headerComponent,
  footerComponent,
}) => (
  <Container>
    {headerComponent ?? <MainHeaderNav />}
    <Main>{children}</Main>
    {footerComponent ?? <Footer />}
  </Container>
);

export default PageContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;
