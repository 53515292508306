import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { useTrackCallback } from '@account/analytics';
import { DownloadAppEvents } from '@account/analytics/events';
import type { AccountMetadata } from '@account/copy';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';
import { Link } from '@ecomm/links';

type Props = {
  deepLink?: string;
};

export const GooglePlayBadgeLink: React.FC<React.PropsWithChildren<Props>> = ({
  deepLink,
}) => {
  const { track } = useTrackCallback();
  const link = deepLink || Links.playStoreDigitalLink.href;
  return (
    <Link
      aria-label="Get it on Google Play"
      data-test-id="google-play-badge"
      to={link}
      target="_blank"
      onClick={() =>
        track({
          event: DownloadAppEvents.GooglePlay,
          instanceProperties: {
            linkTo: link,
            unitName: 'android appstore logo',
            parent: 'Downloads module',
          },
        })
      }
    >
      <GooglePlayBadge />
    </Link>
  );
};

export const AppleBadgeLink = () => {
  const { track } = useTrackCallback();
  return (
    <Link
      aria-label="Download on the App Store"
      data-test-id="app-store-badge"
      to={Links.appStoreDigitalLink.href}
      target="_blank"
      onClick={() =>
        track({
          event: DownloadAppEvents.AppleBadge,
          instanceProperties: {
            linkTo: Links.appStoreDigitalLink.href,
            unitName: 'iOS appstore logo',
            parent: 'Downloads module',
          },
        })
      }
    >
      <AppleBadge />
    </Link>
  );
};

const AppStoreImg = styled.img`
  height: 40px;
`;

type BadgeProps = { id: AccountMetadata };

const ImageBadge: React.FC<React.PropsWithChildren<BadgeProps>> = ({ id }) => {
  const { image, description } = useAccountMetadata(id);

  return <AppStoreImg src={image.url} alt={description} loading="lazy" />;
};

export const AppleBadge = () => <ImageBadge id="appIcon.appleStore" />;
export const GooglePlayBadge = () => <ImageBadge id="appIcon.googlePlay" />;
