import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import React from 'react';
import {
  ALLOW_GRAPHQL_DEV_TOOLS,
  HIDE_CONSOLE_LOGS,
  USE_APOLLO_V3_ACCOUNT,
  USE_APOLLO_V3,
} from '@peloton/app-config';
import { useOauth } from '@peloton/auth';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import type { ExtLinkEnv } from '@peloton/external-links';
import { Provider as GraphQLProvider } from '@peloton/graphql';
import { toLink, toUri } from '@peloton/graphql/toClient';
import { toClientNameAndVersion } from '@peloton/graphql/toClientNameAndVersion';
import { redirect } from '@peloton/navigation';
import { useLoginUrl } from '@account/auth/OauthProvider';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';

const NOT_LOGGED_IN_MESSAGE = 'Not logged in';

export const getHeadersWithToken = (headers: object, token?: string | null) => {
  if (!token) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};

const Provider: React.FC<React.PropsWithChildren<{ env: ExtLinkEnv; name: string }>> = ({
  env,
  name,
  ...props
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useOauth();
  const loginUrl = useLoginUrl();
  const [checkoutAccessToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, null);
  let checkoutToken = checkoutAccessToken as string | null;
  if (typeof checkoutToken === 'string') {
    // remove potential double quotes from token being stringified
    checkoutToken = checkoutToken.replace(/"/g, '');
  }
  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return getHeadersWithToken(headers, token);
    } else {
      return getHeadersWithToken(headers, checkoutToken);
    }
  }) as ApolloLink;

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const errorMessages = graphQLErrors.map(({ message }) => message);
      if (errorMessages.includes(NOT_LOGGED_IN_MESSAGE)) {
        redirect(loginUrl(window.location.href));
      }
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: true }),
    link: ApolloLink.from([errorLink, authLink, toLink(toUri(env))]),
    connectToDevTools: ALLOW_GRAPHQL_DEV_TOOLS,
    ...toClientNameAndVersion(name),
  });

  if (USE_APOLLO_V3 && USE_APOLLO_V3_ACCOUNT) {
    if (!HIDE_CONSOLE_LOGS) {
      console.log('Account Apollo V2 Provider is disabled');
    }
    return <>{props.children}</>;
  }

  return <GraphQLProvider client={client}>{props.children}</GraphQLProvider>;
};

export default Provider;
