import { useQuery as useQueryV3 } from '@apollo/client';
import { pathOr } from 'ramda';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { isAdminEnv, isTestEnv } from '@peloton/env/models';
import type {
  QueryHookOptionsV2 as TQueryHookOptions,
  QueryHookResultV2 as QueryHookResult,
  DocumentNodeV2 as DocumentNode,
  OperationVariablesV2 as OperationVariables,
} from '@peloton/graphql/apolloV2';
import { generatePersonalizationContentfulKeyPrefix } from '@peloton/split-testing/redux/optimizely';
import processContentfulData from '@ecomm/copy-xray/processContentfulData';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import Context from './context';
export type { QueryHookResultV2 as QueryResult } from '@peloton/graphql/apolloV2';

const useBaseQuery = useQueryV3;

export type QueryHookOptions<TData, TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;

export const useQuery = <TData, TVariables>(query: DocumentNode, options?: any) => {
  const personalizedOptions = usePersonalizedOptions(options);
  const secretOptions = useSecretOptions(options);

  const { data, ...rest } = preferPersonalizedContentThenSecretContent({
    defaultContent: useContentfulApolloQuery(query, options),
    personalizedContent: useContentfulApolloQuery(query, personalizedOptions),
    secretContent: useContentfulApolloQuery(query, secretOptions),
  });

  const isCopyXrayActive = useIsToggleActive()('copyXray');
  const copyXrayEnabled = isAdminEnv() || isTestEnv() ? false : isCopyXrayActive;

  return {
    data: copyXrayEnabled ? processContentfulData(data) : data,
    ...rest,
  };
};

export const useContentfulApolloQuery = <TData, TVariables>(
  query: DocumentNode,
  options: any,
): QueryHookResult<TData, TVariables> => {
  const contextValue = useContext(Context);

  const client = contextValue?.client;

  const variables = {
    ...pathOr({}, ['defaultOptions', 'query', 'variables'], client),
    ...(options.variables || {}),
  };

  const cacheResult = {
    data: {},
    loading: false,
    error: undefined,
  } as QueryHookResult<TData, TVariables>;

  const skipEntirely = !!options.skipQuery;

  if (!skipEntirely) {
    try {
      const result = client?.readQuery<TData, TVariables>({ query, variables });
      if (result) {
        cacheResult.data = result;
      }
    } catch (e) {
      // readQuery will throw an error if the data does not exist in the cache, so this
      // catch is needed so that we can treat that as a cache miss instead of erroring the whole hook
    }
  }

  // Only skip the base query if there is no fetch policy provided in the options
  const preferCacheResult = !options.fetchPolicy;

  const baseQueryResult = useBaseQuery<TData, OperationVariables>(query, {
    skip: preferCacheResult || skipEntirely,
    client,
    overrideClient: client,
    fetchPolicy: 'cache-only',
    ...options,
    variables,
  });
  // @ts-expect-error
  return preferCacheResult ? cacheResult : baseQueryResult;
};

const usePersonalizedOptions = (options: any) => {
  const personalizedOptions = {
    ...options,
    variables: { ...(options.variables || {}) },
  };

  const personalizedContentfulKeyPrefix = useSelector(
    generatePersonalizationContentfulKeyPrefix,
  );

  const singleEntryKey = personalizedOptions.variables?.id;
  if (singleEntryKey) {
    personalizedOptions.variables.id = `${personalizedContentfulKeyPrefix}${singleEntryKey}`;
  }

  personalizedOptions.skipQuery = !personalizedContentfulKeyPrefix;

  return personalizedOptions;
};

type OptionsType = {
  variables: {
    id?: string;
  };
  skipQuery?: boolean;
};

const useSecretOptions = (options: OptionsType): OptionsType => {
  const secretOptions = {
    ...options,
    variables: { ...(options.variables || {}) },
    skipQuery: true,
  };

  if (process.env.REACT_APP_PRIVATE_ENV) {
    const singleEntryKey = secretOptions.variables.id;
    if (singleEntryKey) {
      secretOptions.variables.id = `rainforestCafePrelaunch.${options.variables.id}`;
      secretOptions.skipQuery = false;
    }
  }

  return secretOptions;
};

type GenericQueryHookResult = QueryHookResult<any, OperationVariables>;
type ContentForPreferenceEvaluation = {
  personalizedContent: GenericQueryHookResult;
  defaultContent: GenericQueryHookResult;
  secretContent: GenericQueryHookResult;
};

const _isPrioritizedDataFound = (data: Record<string, unknown> | undefined): boolean => {
  // If the data is undefined, the query was skipped and there is no data
  if (!data) {
    return false;
  }

  // If there are no keys on the data object, the query was made against the apollo cache, but
  // there was no result for either the prefixed personalized key or prefixed secret key.
  const resultHasNoValues = !Object.keys(data).length;
  if (resultHasNoValues) {
    return false;
  }

  /*
    Markdown pages and product recommendations fetch filtered collections instead of
    single documents like the other queries. This makes it difficult for us to tell if a
    query for secret or personalized markdown copy has returned a value, so we treat all
    collections as not having "prioritized" copy so that the default content is returned.
    */
  const isCollection =
    data.markdownPageCollection || data.productRecommendationsCohortCollection;
  if (isCollection) {
    return false;
  }

  return true;
};

export const preferPersonalizedContentThenSecretContent = ({
  personalizedContent,
  defaultContent,
  secretContent,
}: ContentForPreferenceEvaluation) => {
  const personalizedContentFound = _isPrioritizedDataFound(personalizedContent.data);
  const secretContentFound = _isPrioritizedDataFound(secretContent.data);

  if (personalizedContentFound) {
    return personalizedContent;
  }

  if (secretContentFound) {
    return secretContent;
  }

  return defaultContent;
};

// These hooks are available in @apollo/react-hooks (and the upcoming @apollo/client 3.0)
export const useLazyQuery = <TData, TVariables>(_: DocumentNode, __?: any) => {
  console.error(
    '@ecomm/copy needs to upgrade to @apollo/react-hooks in order to use this feature',
  );
};

export type LazyQueryHookOptions<TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;
