import { Icon, Flex, Eyebrow, spacing, Container } from '@pelotoncycle/design-system';
import React from 'react';
import { AccountCopy } from '@account/copy';
import { confirmationIconEyebrow } from './copy';

const ConfirmationIcon: React.FC<
  React.PropsWithChildren<{ alignItemsDesktop?: string }>
> = ({ alignItemsDesktop = 'center' }) => (
  <Flex
    flexDirection="column"
    alignItems={{ mobile: 'center', desktop: alignItemsDesktop }}
  >
    <Container
      height={{ mobile: '26px', tablet: '44px' }}
      width={{ mobile: '26px', tablet: '44px' }}
      verticalMargin={`0 ${spacing[24]}`}
    >
      <Icon name="celebration" width="100%" height={44} style={{ maxHeight: '100%' }} />
    </Container>
    <Eyebrow size="medium" style={{ marginBottom: `${spacing[16]}` }}>
      <AccountCopy id={confirmationIconEyebrow} />
    </Eyebrow>
  </Flex>
);

export default ConfirmationIcon;
