import { createInstance } from '@optimizely/react-sdk';
import type { FeatureEnvironments } from '@account/bootstrapping/features/static';
import { datafiles, sdkKeys } from '@account/bootstrapping/features/static';

export const toReactClient = (env: FeatureEnvironments) =>
  createInstance({
    sdkKey: sdkKeys[env],
    datafile: datafiles[env],
    logLevel: 3,
  });
