import { grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { getUserAvatarUrl, getUserEmail, getUserFullName } from '@peloton/auth';
import { media } from '@peloton/styles';
import { useAccountKeyValue } from '@account/copy';
import { SimpleAvatar } from '@acme-ui/images/SimpleAvatar';
import { useReduxState } from '@engage/redux';

const HeaderAvatar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const avatarUrl = useReduxState(getUserAvatarUrl);
  const email = useReduxState(getUserEmail);
  const riderName = useReduxState(getUserFullName);

  return (
    <UserAvatar
      url={avatarUrl}
      size={32}
      alt={useAccountKeyValue('account.nav.avatar.alt', {
        name: riderName ? riderName : email,
      })}
    />
  );
};

const UserAvatar = styled(SimpleAvatar)`
  height: 32px;
  width: 32px;
  border: 1px solid ${transparentize(0.7, grey[50])};
  margin-right: 16px;

  ${media.tablet`
    margin-right: 24px;
  `};

  ${media.desktop`
    margin-right: 32px;
  `};
`;

export default HeaderAvatar;
