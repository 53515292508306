import { grey } from '@pelotoncycle/design-system';
import { Image } from 'cloudinary-react';
import React from 'react';
import { compose, withState } from 'recompose';
import styled, { css } from 'styled-components';
import type { Breakpoint, SwitchConfig } from '@peloton/responsive';
import { BreakpointSwitch } from '@peloton/responsive';
import { Image as SimpleImage } from './Image';

// Avatar is to be deprecated in favor of SimpleAvatar
// See Jupiter PR#3944 for background:
// Avatar is less performant due to nesting, noticeable in longer lists.
const SimpleAvatar: React.FC<React.PropsWithChildren<SimpleAvatarProps>> = ({
  url: src,
  size,
  ...props
}) =>
  src ? (
    <StyledSimpleImage
      breakpointOptions={toAvatarTransforms(size)}
      src={src}
      className="fs-exclude"
      {...props}
    />
  ) : (
    <NoImage size={size} {...props} />
  );

const placeholderStyles = css`
  background-color: ${grey[40]};
  border-radius: 50%;
`;

const NoImage = styled.div<{ size: number }>`
  ${placeholderStyles}
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const StyledSimpleImage = styled(SimpleImage)`
  ${placeholderStyles}
  overflow: hidden;
`;

const toAvatarTransforms = (size: number) => ({
  mobile: {
    aspectRatio: 1,
    gravity: 'face',
    height: size,
    width: size,
  },
});

const ResponsiveAvatar: React.FC<React.PropsWithChildren<ResponsiveImageProps>> = ({
  size,
  ...props
}) =>
  typeof size === 'number' ? (
    <ImageWithLoading size={size} {...props} />
  ) : (
    <BreakpointSwitch breakpoints={toSwitchConfig(size, props)} />
  );

const Avatar = styled(ResponsiveAvatar)`
  border-radius: 100%;
`;

const ImageWithLoadingView: React.FC<React.PropsWithChildren<LoaderProps>> = ({
  isLoading,
  setIsLoading,
  loadingColor,
  alt,
  ...htmlProps
}) => (
  <>
    {isLoading ? (
      <Placeholder
        data-test-id="loading"
        loadingColor={loadingColor}
        {...htmlProps}
        style={{ border: 'none' }}
      />
    ) : null}
    <ImageWithProps
      style={isLoading ? { display: 'none' } : {}}
      onLoad={() => setIsLoading(false)}
      alt={alt}
      {...htmlProps}
    />
  </>
);

const ImageWithLoading = compose<LoaderProps, ImageProps>(
  withState('isLoading', 'setIsLoading', true),
)(ImageWithLoadingView);

export const ImageWithProps: React.FC<React.PropsWithChildren<ImageProps>> = ({
  size,
  url,
  ...htmlProps
}) => (
  <StyledImage
    crop="fill"
    gravity="face"
    publicId={url}
    radius="max"
    width={size}
    height={size}
    aspectRatio="1"
    size={size}
    {...htmlProps}
  />
);

const toSwitchConfig = (size: ResponsiveConfig, props: ImageWithoutSize) =>
  Object.keys(size).reduce(
    (config: SwitchConfig, breakpoint: Breakpoint) => ({
      ...config,
      [breakpoint]: <ImageWithLoading size={size[breakpoint] as number} {...props} />,
    }),
    {},
  );

const Placeholder = styled.div`
  background-color: ${(props: PlaceholderProps) => props.loadingColor ?? grey[40]};
  min-width: ${(props: PlaceholderProps) => `${props.size}px`};
  width: ${(props: PlaceholderProps) => `${props.size}px`};
  height: ${(props: PlaceholderProps) => `${props.size}px`};
`;
Placeholder.displayName = 'Placeholder';

const StyledImage = styled(Image)`
  min-width: ${(props: { size: number }) => `${props.size}px`};
  width: ${(props: { size: number }) => `${props.size}px`};
  height: ${(props: { size: number }) => `${props.size}px`};
`;

type SimpleAvatarProps = React.HTMLAttributes<HTMLDivElement> & {
  size: number;
  alt: string;
  url?: string;
};

type ImageProps = React.HTMLAttributes<HTMLDivElement> & {
  size: number;
  url?: string;
  loadingColor?: string;
  alt: string;
};

type LoaderProps = ImageProps & {
  isLoading: boolean;
  setIsLoading: (newVal: boolean) => void;
};

type PlaceholderProps = {
  loadingColor?: string;
  size: number;
};

type ResponsiveImageProps = ImageWithoutSize & { size: Size };

type ImageWithoutSize = Pick<ImageProps, Exclude<keyof ImageProps, 'size'>>;
type Size = number | ResponsiveConfig;
type ResponsiveConfig = Partial<Record<Breakpoint, number>>;

export default Avatar;
export { ImageWithLoadingView, SimpleAvatar };
