import type { AccountKeyValue } from '@account/copy';

export const MENU_WIDTH = 244;

export const MENU_LINK_CONTENT: {
  hrefKey: AccountKeyValue;
  textKey: AccountKeyValue;
}[] = [
  {
    hrefKey: 'account.nav.account.classes.href',
    textKey: 'account.nav.account.classes.text',
  },
  {
    hrefKey: 'account.nav.account.profile.href',
    textKey: 'account.nav.account.profile.text',
  },
  {
    hrefKey: 'account.nav.account.membership.href',
    textKey: 'account.nav.account.membership.text',
  },
  {
    hrefKey: 'account.nav.account.orders.href',
    textKey: 'account.nav.account.orders.text',
  },
];
