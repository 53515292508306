import { brand, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { PelotonText } from '@peloton/logos';
import { media } from '@peloton/styles';
import { useImageData, useKeyValueData } from '@ecomm/copy';
import { Link } from '@ecomm/internationalize-ui';
import { Audience } from '@onewellness/routes';
import { contentKeyForAudience } from '@onewellness/utils/copy';
import HyattLogo from './HyattLogo';
import Logo from './Logo';
import LululemonLogo from './LululemonLogo';
import YMCALogo from './YMCALogo';

export const DESKTOP_HEIGHT = 68;
export const MOBILE_HEIGHT = 48;
const PARTNERSHIP_DESKTOP_LOGO_HEIGHT = 37;

type Props = React.HTMLProps<HTMLElement> & {
  audience?: Audience;
  headerStyle?: 'light' | 'dark';
};

const AudienceLogo: React.FC<
  React.PropsWithChildren<{ audience: Audience; fill: string }>
> = ({ audience, fill }) => {
  const { image } = useImageData(
    contentKeyForAudience('onewellness.{audience}.header.logo', audience),
  );
  let logo;

  if (audience === Audience.Lululemon || audience === Audience.LululemonHardware) {
    logo = <LululemonLogo data-test-id="lululemon-logo" />;
  } else if (audience === Audience.YMCA) {
    logo = <YMCALogo data-test-id="ymca-logo" />;
  } else if (audience === Audience.Hyatt) {
    logo = <HyattLogo data-test-id="hyatt-logo" />;
  } else {
    logo = (
      <PartnershipLogo
        src={image.mobile.url}
        alt={image.mobile.description}
        data-test-id="1wHeaderLogo"
      />
    );
  }

  return (
    <LogoContainer>
      <LargePelotonLogo data-test-id="1wPeloton-Logo" fill={fill} />
      <DividerLine />
      {logo}
    </LogoContainer>
  );
};

export const HeaderView: React.FC<React.PropsWithChildren<Props>> = ({
  audience = undefined,
  headerStyle = 'light',
}) => {
  const homeLinkLabel = useKeyValueData('header.homeLinkLabel');

  const background = headerStyle === 'light' ? brand.lightest : brand.darkest;
  const fill = headerStyle === 'light' ? brand.darkest : brand.lightest;

  let partnershipLogo = false;
  let logo;
  switch (audience) {
    case Audience.Corporate:
      logo = <CorporateWellnessLogo fill={fill} />;
      break;
    case Audience.DicksSportingGoods:
    case Audience.Hyatt:
    case Audience.Lululemon:
    case Audience.LululemonHardware:
    case Audience.YMCA:
      partnershipLogo = true;
      logo = <AudienceLogo audience={audience} fill={fill} />;
      break;
    default:
      logo = <PelotonTextStyled fill={fill} />;
      break;
  }

  return (
    <Background background={background}>
      <PaddedContainer style={partnershipLogo ? { justifyContent: 'center' } : {}}>
        <Link
          aria-label={homeLinkLabel}
          href={Links.peloton.path}
          hasUnderline={false}
          data-test-id="link"
        >
          {logo}
        </Link>
      </PaddedContainer>
    </Background>
  );
};

const Background = styled.header<{ background: string }>`
  align-items: center;
  background: ${({ background }) => background};
`;

const PaddedContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${MOBILE_HEIGHT}px;
  padding-left: ${spacing[16]};

  ${media.desktop`
    justify-content: unset;
    height: ${DESKTOP_HEIGHT}px;
    padding-left: ${spacing[32]};
  `}
`;

const CorporateWellnessLogo = styled(Logo)`
  height: 11px;
  vertical-align: middle;

  ${media.desktopXLarge`
    height: 16px;
  `}
`;

const PelotonTextStyled = styled(PelotonText)`
  height: 11px;
  vertical-align: middle;

  ${media.desktopXLarge`
    height: 16px;
  `}
`;

const PartnershipLogo = styled.img`
  vertical-align: middle;
  height: ${PARTNERSHIP_DESKTOP_LOGO_HEIGHT}px;
`;

const LargePelotonLogo = styled(PelotonText)`
  height: 35px;
  vertical-align: middle;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * + * {
    margin-left: 20px;
  }

  svg:first-of-type {
    width: 98px;
    height: 29px;
  }
`;

const DividerLine = styled.div`
  background: ${white};
  width: 1px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
`;
