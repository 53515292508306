import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { Peloton, PelotonMark } from '@peloton/logos';
import { BreakpointSwitch } from '@peloton/responsive';
import { useKeyValueData } from '@ecomm/copy';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';

export const GenericLogo: React.FC<React.PropsWithChildren<{ label: string }>> = ({
  label,
}) => (
  <Container>
    <StyledLogoLink to={Links.peloton} aria-label={label}>
      <BreakpointSwitch
        breakpoints={{
          mobile: <StyledMarkLogo data-test-id="pelotonLogo" />,
          tabletXLarge: <StyledFullLogo data-test-id="pelotonLogo" />,
          desktopLarge: <StyledMarkLogo data-test-id="pelotonLogo" />,
          desktopXLarge: <StyledFullLogo data-test-id="pelotonLogo" />,
        }}
      />
    </StyledLogoLink>
  </Container>
);

const EcommLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const label = useKeyValueData('header.homeLinkLabel');

  return <GenericLogo label={label} />;
};

export default EcommLogo;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const StyledLogoLink = (styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const StyledMarkLogo = styled(PelotonMark)`
  height: 28px;
`;

const StyledFullLogo = styled(Peloton)`
  width: 120px;
`;
