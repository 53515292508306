export const slugsToTierLevel: Record<string, number> = {
  'app-free': 0,
  'app-one': 1,
  'app-plus': 2,
};

export const GUEST_PASS_SLUG = 'guest-pass';
export const SIXTY_DAY_TRIAL = 'sixty-day';
export const promoSlugs = [GUEST_PASS_SLUG, SIXTY_DAY_TRIAL] as const;

export const LULULEMON_APP_ONE_SLUG = 'app-one';
export const LULULEMON_APP_PLUS_SLUG = 'app-plus';
export const LULULEMON = 'lululemon';

export const HYATT_APP_ONE_SLUG = 'app-one';
export const HYATT_APP_PLUS_SLUG = 'app-plus';
export const HYATT = 'hyatt';

export const promoPartners = [LULULEMON];
export const lululemonPromoEligibleSlugs = [
  LULULEMON_APP_ONE_SLUG,
  LULULEMON_APP_PLUS_SLUG,
];

export type PromoSlug = typeof promoSlugs[number];
export type PromoPartnerSlug = typeof lululemonPromoEligibleSlugs[number];
export type PromoPartner = typeof promoPartners[number];
