import { grey, white } from '@pelotoncycle/design-system';
import React from 'react';
import type { RefObject } from 'react';
import styled from 'styled-components';
import { defaultTransition, hover, media } from '@peloton/styles';
import { hb6, hb8 } from '@acme-ui/typography';
import { Chevron, Orientation } from '@ecomm/icons';

type StyledMenuItemProps = {
  text?: string;
  testId: string;
  expanded?: boolean;
  onClick?: any;
  itemRef: RefObject<HTMLButtonElement>;
};

const MenuItem: React.FC<React.PropsWithChildren<StyledMenuItemProps>> = ({
  text,
  testId,
  expanded = false,
  onClick,
  itemRef,
}) => {
  return (
    <StyledMenuItem
      id="accountMenuItem"
      aria-haspopup="true"
      aria-expanded={expanded}
      data-test-id={`${testId}`}
      onClick={onClick}
      ref={itemRef}
      className={expanded ? 'expanded' : undefined}
    >
      <div>
        <StyleButtonText>{text}</StyleButtonText>
        <Chevron
          aria-hidden="true"
          orientation={expanded ? Orientation.Up : Orientation.Down}
        />
      </div>
    </StyledMenuItem>
  );
};

export default MenuItem;

const StyledMenuItem = styled.button`
  ${hb8}
  position: relative;
  color: ${white};
  width: auto;
  height: 56px;
  padding: 0 16px;
  margin-right: 12px;
  display: inline-block;
  cursor: pointer;

  ${media.desktopXLarge`
    ${hb6}
  `};

  ${defaultTransition()}
  ${hover`
    color: ${grey[50]}
  `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    bottom: -8px;
    left: calc((100% - 16px) / 2);
    transform: rotate(90deg);
    opacity: 0;
  }

  &.expanded::before {
    opacity: 1;
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    &::after {
      color: ${white};
    }
  }

  div svg {
    fill: ${grey[50]};
    height: 5px;
    width: 8px;
    margin: -2px 0 0 3px;
    pointer-events: none;
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: ease;
  }

  ${media.desktop`
    margin-right: 24px;
  `};
`;

const StyleButtonText = styled.span`
  margin-right: 6px;
`;
