type MobileProps = {
  isAndroid: boolean;
  isiOS: boolean;
};

const useMobilePlateform = (): MobileProps => {
  const sanitizedUserAgent = navigator.userAgent.toLowerCase();
  const isAndroid = sanitizedUserAgent.includes('android');
  const iPadVersion13 =
    sanitizedUserAgent.includes('mac') &&
    (navigator.maxTouchPoints > 1 || 'ontouchstart' in window);
  const isiOS = /ipad|iphone|ipod/.test(sanitizedUserAgent) || iPadVersion13;

  return {
    isAndroid,
    isiOS,
  };
};

export default useMobilePlateform;
