import { grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { nest } from 'recompose';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import useRequiresAuth from '@account/auth/useRequiresAuth';
import { DomainBasedCopyProvider, useAccountKeyValue } from '@account/copy';
import { Faqs } from '@onewellness/ui/Faqs';
import { HyattFaqsCopyContext } from '@onewellness/ui/Faqs/copy/account';
import { PageWrapper, Avatar, Body } from './shared';
import { useHyattConnectUrl } from './useHyattConnectUrl';

const Page: React.FC = () => {
  useRequiresAuth();

  const headline = useAccountKeyValue('hyatt.offer.headline');
  const body = useAccountKeyValue('hyatt.offer.body');
  const cta = useAccountKeyValue('hyatt.offer.connectCta');
  const href = useHyattConnectUrl();

  return (
    <PageWrapper>
      <Container>
        <Avatar showName />
        <Body
          width="400px"
          headlineText={headline}
          bodyText={body}
          cta={{
            href,
            text: cta,
          }}
        />
      </Container>
      <FaqContainer>
        <HyattFaqsCopyContext>
          <FaqWrapper>
            <Faqs id="hyatt-faq" />
          </FaqWrapper>
        </HyattFaqsCopyContext>
      </FaqContainer>
    </PageWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 1rem;
  width: 100%;
  background: ${grey[80]};
`;

const FaqWrapper = styled.div`
  max-width: ${BreakpointWidth.desktop}px;
  margin: auto;
  padding: ${spacing[48]} 0;
`;

const FaqContainer = styled.div`
  background: ${grey[30]};
`;

export default nest(DomainBasedCopyProvider, Page);
