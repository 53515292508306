import { useSelector } from 'react-redux';
import { getUserTrackingProperties } from '@ecomm/auth';

type UserProps = {
  isLoggedIn: boolean;
  hasDeviceSubscription: boolean;
  hasDigitalSubscription: boolean;
};

export type TrackingProps = {
  category: string;
  parent?: string;
  unitName?: string;
  linkTo?: string;
  linkName: string;
};

export type TrackingAndUserProps = Required<TrackingProps> & UserProps;

const trackingDefaults = {
  category: '',
  parent: '',
  unitName: '',
  linkTo: '',
  linkName: '',
};

const useTrackingAndUserInfo = (trackingInfo: TrackingProps): TrackingAndUserProps => {
  const { isLoggedIn, hasDeviceSubscription, hasDigitalSubscription } = useSelector(
    getUserTrackingProperties,
  );

  const trackingInfoWithDefaults: Required<TrackingProps> = Object.keys(
    trackingDefaults,
  ).reduce(
    (res, trackingKey) => ({
      ...res,
      [trackingKey]: trackingInfo[trackingKey] || trackingDefaults[trackingKey],
    }),
    trackingDefaults,
  );

  return {
    ...trackingInfoWithDefaults,
    isLoggedIn,
    hasDeviceSubscription,
    hasDigitalSubscription,
  };
};

export default useTrackingAndUserInfo;
