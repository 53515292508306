import type { AccountKeyValue } from '@account/copy';

type UpgradeKey = 'headline';

export const upgradeCopy: Record<UpgradeKey, AccountKeyValue> = {
  headline: 'subscriptionMobility.confirmation.upgradeHeadline',
};

type ThirdPartyManageKey = 'headline' | 'body';

export const thirdPartyManage: Record<ThirdPartyManageKey, AccountKeyValue> = {
  headline: 'subscriptionMobility.confirmation.thirdPartyManageHeadline',
  body: 'subscriptionMobility.confirmation.thirdPartyManageBody',
};

export const confirmationIconEyebrow: AccountKeyValue =
  'subscriptionMobility.confirmation.newUserEyebrow';
