import { Container, Flex, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';
import { AccountCopy } from '@account/copy';
import { newUserCopy } from './copy';

const SocialProspectsDownloadAppDesktop = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={{
        tablet: `${spacing[56]} 0`,
        desktop: `${spacing[56]} 0`,
      }}
      centered
      gap="40px"
    >
      <Container
        id="apps-flyer-QR-code"
        data-test-id="apps-flyer-QR-code"
        textAlign={'center'}
      />
      <Support size="large" textAlign={'center'} style={{ maxWidth: '220px' }}>
        <AccountCopy id={newUserCopy.mobileProfileSetup} />
      </Support>
    </Flex>
  );
};

export default SocialProspectsDownloadAppDesktop;
