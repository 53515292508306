import { camelizeKeys } from 'humps';
import { TEAM_API_BASE_URL } from '@peloton/app-config';
import { toNowTimezoneString } from '@peloton/time';
import logAction from '@ecomm/logging/logAction';
import type { InviteDataResponse } from '../models/personalizedData.types';

export const fetchInviteUrl = (inviteId: string) =>
  `${TEAM_API_BASE_URL}/invites/${inviteId}`;

// TODO: Once we get the CORS issue working we may need to adjust
export const fetchInvitation = async (inviteId: string): Promise<InviteDataResponse> => {
  const response = await fetch(fetchInviteUrl(inviteId), {
    headers: {
      'peloton-client-date': toNowTimezoneString(),
    },
  }).then(res => {
    return res.json();
  });

  if (response.error_name) {
    logAction('Failed to fetch invite', {
      error: `${response.error_message}`,
    });

    throw new Error(response.error_message);
  }
  return camelizeKeys(response) as InviteDataResponse;
};
