import { useCallback } from 'react';
import { CheckoutEvent } from '../events';
import { useTrackCallback } from '../track';
import type { TrackingProperties } from '../track';

export enum TierCheckoutType {
  FREEMIUM = 'freemium',
  DIGITAL = 'digital',
}

export interface AnalyticsProperties {
  checkoutType: TierCheckoutType;
  trackingProperties: Omit<TrackingProperties, 'event'>;
}

export interface FieldValidationStatusTypes {
  isEmailValid: boolean;
  isPasswordValid: boolean;
}

const checkoutStepValues = {
  email: { step: 2, stepName: 'User Moves away from Email Address Field' },
  password: { step: 3, stepName: 'Digital Password Created' },
};

const useTrackCompleteCheckoutStep = (
  { isEmailValid, isPasswordValid }: FieldValidationStatusTypes,
  { checkoutType, trackingProperties }: AnalyticsProperties,
  emailValue: string,
) => {
  const { track } = useTrackCallback();

  const trackEmail = useCallback(() => {
    const { email: stepDescriptionProperties } = checkoutStepValues;

    if (isEmailValid) {
      track({
        event: CheckoutEvent.CheckoutStepCompleted,
        instanceProperties: {
          ...trackingProperties.instanceProperties,
          ...stepDescriptionProperties,
          email: emailValue,
        },
      });
    }
  }, [emailValue, isEmailValid, track, trackingProperties.instanceProperties]);

  const trackPassword = useCallback(() => {
    const {
      password: { step, stepName },
    } = checkoutStepValues;

    const checkoutTypeTrackingConditionMap = {
      [TierCheckoutType.DIGITAL]: {
        event: CheckoutEvent.CheckoutStepCompleted,
        trackingCondition: isPasswordValid,
        extraProperties: {
          step,
          stepName,
        },
      },
      [TierCheckoutType.FREEMIUM]: {
        event: CheckoutEvent.CreatedPassword,
        trackingCondition: isEmailValid && isPasswordValid,
        extraProperties: {},
      },
    };

    const {
      event,
      trackingCondition,
      extraProperties,
    } = checkoutTypeTrackingConditionMap[checkoutType];

    if (trackingCondition) {
      track({
        event,
        instanceProperties: {
          ...trackingProperties.instanceProperties,
          ...extraProperties,
        },
      });
    }
  }, [
    checkoutType,
    isEmailValid,
    isPasswordValid,
    track,
    trackingProperties.instanceProperties,
  ]);

  return {
    trackEmail,
    trackPassword,
  };
};

export default useTrackCompleteCheckoutStep;
