import type { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { useFeature } from '@optimizely/react-sdk';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthEnv } from '@peloton/auth/authClient';
import { addAuthParamsToV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import POauthProvider from '@peloton/auth/OauthProvider';
import type { UatHostnameEnv } from '@peloton/env-hostnames';
import { toHostnameEnv } from '@peloton/env-hostnames';
import { useErrorReporter } from '@peloton/error-reporting';
import {
  ApiEnvs,
  safelyDecode,
  getExtLinkEnv,
  REDIRECT_URL_QUERY_PARAM,
  REDIRECT_APP_QUERY_PARAM,
  toRedirectUrl,
  toExtLinkEnv,
  AccountLinkEnvs,
  LinkEnvs,
} from '@peloton/external-links';
import { history } from '@peloton/redux';
import { toAuth0Config, accountEnvironmentFlags } from '@account/env';
import { FEATURES } from '@account/optimizely';
import { CALLBACK, LOGIN } from '@account/pg-auth/urls';
import { toAuth0Config as toWWWAuth0Config } from '@ecomm/env/auth0';
import { LOGIN as ECOMM_LOGIN } from '@ecomm/oauth/urls';

const defaultApp = 'account';

const { app: hostApp } = (toHostnameEnv(window.location.hostname) as UatHostnameEnv) ?? {
  app: defaultApp,
};

const loginPath = hostApp === 'www' ? ECOMM_LOGIN : LOGIN;

export const toAppState = (redirectUrl: string, app: string = defaultApp) => ({
  [REDIRECT_URL_QUERY_PARAM]: btoa(redirectUrl),
  [REDIRECT_APP_QUERY_PARAM]: app,
});

export const toLoginRoute = (redirectUrlParam: string, path: string = LOGIN) =>
  `${path}?${REDIRECT_URL_QUERY_PARAM}=${btoa(redirectUrlParam)}`;

export const decodeRedirectUrl = (record: Record<string, string>) =>
  safelyDecode(record[REDIRECT_URL_QUERY_PARAM]) || '/';

const useRedirectUrl = () => {
  const extLinkEnv = useSelector(getExtLinkEnv);
  const environmentFlags = accountEnvironmentFlags();

  return useCallback(
    (path: string) => {
      if (environmentFlags.isLocal) {
        return toRedirectUrl({
          extLinkEnv: toExtLinkEnv({
            account: AccountLinkEnvs.Local,
            www: LinkEnvs.Local,
          }),
          path,
          type: hostApp,
        });
      }

      if (environmentFlags.isDeployPreview) {
        return `${window.location.protocol}//${extLinkEnv.hostname}${path}`;
      }

      return toRedirectUrl({ extLinkEnv, path, type: hostApp });
    },
    [extLinkEnv],
  );
};

export const useLoginUrl = () => {
  const redirectUrl = useRedirectUrl();
  return useCallback(
    (redirectUrlParam: string) => redirectUrl(toLoginRoute(redirectUrlParam, loginPath)),
    [redirectUrl],
  );
};

const toAuthEnv = (apiEnv: ApiEnvs): AuthEnv => {
  if (apiEnv === ApiEnvs.Qa1) {
    return AuthEnv.Stage;
  }
  return AuthEnv.Prod;
};

const OauthProvider: React.FC<React.PropsWithChildren<{ apiEnv: ApiEnvs }>> = props => {
  const redirectUrl = useRedirectUrl();
  const { errorReporter } = useErrorReporter();
  const auth0ConfigPath = hostApp === 'www' ? toWWWAuth0Config : toAuth0Config;
  const auth0Config = auth0ConfigPath(props.apiEnv);
  const redirectUri = { redirect_uri: redirectUrl(CALLBACK) };
  const [isMemoryStorageEnabled] = useFeature(FEATURES.IsMemoryStorageEnabled);

  return (
    <POauthProvider
      {...props}
      {...addAuthParamsToV2ConfigSchema(auth0Config, redirectUri)}
      onRedirectCallback={async (state: RedirectLoginResult['appState']) => {
        history.replace(
          `${window.location.pathname}?redirectUrl=${btoa(decodeRedirectUrl(state))}`,
        );
      }}
      onErrorCallback={(e, action) => {
        errorReporter.reportError(
          new Error(`OauthProvider | ${action} | ${e.toString()}`),
        );
      }}
      authEnv={toAuthEnv(props.apiEnv)}
      cacheLocation={isMemoryStorageEnabled ? 'memory' : 'localstorage'}
    >
      {props.children}
    </POauthProvider>
  );
};

export default OauthProvider;
