import React from 'react';
import { track, getBrowser, toDeviceCategoryFromWindow } from '@peloton/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import type { TrackingProps, TrackingAndUserProps } from './hooks';
import { useTrackingAndUserInfo } from './hooks';

type ClickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent> &
  React.MouseEvent<HTMLButtonElement, MouseEvent>;

export type TrackedNavLinkProps = LinkProps & { trackingProps: TrackingProps };

type ToOnClickProps = {
  trackingAndUserProps: TrackingAndUserProps;
  onClick?: (event: ClickEvent) => void;
};

export const handleTrackedOnClick = ({
  trackingAndUserProps,
  onClick,
}: ToOnClickProps) => (event: ClickEvent) => {
  onClick && onClick(event);
  track({
    event: 'Clicked Navigation',
    properties: {
      page: getCurrentRelativePathname(),
      deviceCategory: toDeviceCategoryFromWindow(),
      browser: getBrowser(),
      ...trackingAndUserProps,
    },
  });
};

const TrackedNavLink: React.FC<React.PropsWithChildren<TrackedNavLinkProps>> = ({
  trackingProps,
  onClick,
  ...linkProps
}) => {
  const trackingAndUserProps = useTrackingAndUserInfo(trackingProps);
  const handleClick = React.useMemo(
    () => handleTrackedOnClick({ trackingAndUserProps, onClick }),
    [trackingProps, onClick],
  );
  return <Link {...linkProps} onClick={handleClick} />;
};

export default TrackedNavLink;
