/**
 * This function is used to get the AppsFlyer link.
 * It depends on the window object and the AF_SMART_SCRIPT object.
 *
 * Ensure that the AF_SMART_SCRIPT object is available on the window object.
 * Documentation: https://dev.appsflyer.com/hc/docs/dl_smart_script_v2
 * @returns url string
 */

import logAction from '@ecomm/logging/logAction';

const ONE_LINK_URL = 'https://onepeloton.onelink.me/Wabb/osdnbur6';

type CustomLinkParams = {
  paramKey: string;
  defaultValue: string;
};

interface Props {
  deepLinkValue?: string;
  mediaSource?: string;
  customParams?: CustomLinkParams[];
  logoUrl?: string;
}

export const generateAppsFlyerLink = ({
  deepLinkValue = '',
  mediaSource = 'onepeloton',
  customParams = [],
  logoUrl,
}: Props): string | null => {
  // @ts-expect-error
  if (typeof window === 'undefined' || window.AF_SMART_SCRIPT === undefined) {
    console.error('AF_SMART_SCRIPT is not available on the window object');
    return null;
  }
  if (deepLinkValue === '') {
    console.error('deepLinkValue is required');
    return null;
  }
  // Aggregate custom parameters
  const customParameters: CustomLinkParams[] = customParams.map(customItem => {
    return { paramKey: customItem.paramKey, defaultValue: customItem.defaultValue };
  });

  // @ts-expect-error
  const result = window?.AF_SMART_SCRIPT?.generateOneLinkURL({
    oneLinkURL: ONE_LINK_URL,
    afParameters: {
      mediaSource: { keys: ['utm_source'], defaultValue: mediaSource },
      deepLinkValue: {
        keys: ['page_name'],
        defaultValue: encodeURIComponent(deepLinkValue),
      },
      afCustom: customParameters,
    },
  });

  if (!result) {
    logAction('Failed to generate AppsFlyer link', { deepLinkValue });

    console.error('Unable to generate AppsFlyer link');
    return null;
  }

  // If there is a component with the id apps-flyer-QR-code on the page, it will put a QR code there
  if (logoUrl) {
    const qrOptions = {
      logo: logoUrl,
    };
    // @ts-expect-error
    window?.AF_SMART_SCRIPT?.displayQrCode('apps-flyer-QR-code', qrOptions);
  }

  return result.clickURL;
};
