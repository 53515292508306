import {
  toPostPurchaseFlowPath,
  ORDER_STATUS_SUFFIX,
  toOrderStatusPath,
} from '@account/urls';

export const POST_PURCHASE_FLOW_V2_PREFIX = '/v2/delivery';
export const toPostPurchaseFlowPathV2 = (id: string = ':id') =>
  `${POST_PURCHASE_FLOW_V2_PREFIX}/${id}`;
export const SCHEDULER_SUFFIX = '/schedule';
export const RESCHEDULER_SUFFIX = '/reschedule';
export const SURVEY_SUFFIX = '/survey';
export const PAYMENT_METHOD_SUFFIX = '/rebate';
export const ORDER_TRACKING_SUFFIX = '/tracking';

export enum ScheduleStates {
  reschedule = 'reschedule',
  schedule = 'schedule',
}

export const toSchedulePath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + SCHEDULER_SUFFIX;
export const toReschedulePath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + RESCHEDULER_SUFFIX;
export const toSurveyPath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + SURVEY_SUFFIX;
export const toPaymentMethodPath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + PAYMENT_METHOD_SUFFIX;
export const toOrderStatusPathV2 = (id: string = ':id') =>
  `${POST_PURCHASE_FLOW_V2_PREFIX}/${id}${ORDER_STATUS_SUFFIX}`;
export const toOrderTrackingPath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + ORDER_TRACKING_SUFFIX;

export { toPostPurchaseFlowPath, ORDER_STATUS_SUFFIX, toOrderStatusPath };
