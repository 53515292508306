import type { AccountKeyValue } from '@account/copy';

type NewUserKey =
  | 'headline'
  | 'body'
  | 'lululemonBody'
  | 'receiptLink'
  | 'getStartedHeadline'
  | 'getStartedSubtitle'
  | 'webProfileSetup'
  | 'mobileProfileSetup'
  | 'choice'
  | 'newUserSocialBody'
  | 'newUserSocialHeadline';

export const newUserCopy: Record<NewUserKey, AccountKeyValue> = {
  headline: 'subscriptionMobility.confirmation.newUserHeadline',
  body: 'subscriptionMobility.confirmation.newUserBody',
  lululemonBody: 'subscriptionMobility.confirmation.newLululemonUserBody',
  receiptLink: 'subscriptionMobility.confirmation.newUserReceiptLink',
  getStartedHeadline: 'subscriptionMobility.confirmation.newUserGetStartedHeadline',
  getStartedSubtitle: 'subscriptionMobility.confirmation.newUserGetStartedSubtitle',
  webProfileSetup: 'subscriptionMobility.confirmation.newUserSetupLinkWeb',
  mobileProfileSetup: 'subscriptionMobility.confirmation.newUserSetupLinkMobile',
  choice: 'createAccount.choice',
  newUserSocialBody: 'subscriptionMobility.confirmation.newUserSocialBody',
  newUserSocialHeadline: 'subscriptionMobility.confirmation.newUserSocialHeadline',
};
