import type { ExtLink } from '@peloton/external-links';
import { toWWWLink } from '@peloton/external-links';
import type { AccountKeyValue } from '@account/copy';

// add type for account value here
type Link = {
  href: ExtLink;
  key: AccountKeyValue;
};

export type FooterProps = {
  links: Link[];
};

export const FooterData: FooterProps = {
  links: [
    {
      key: 'footer_links_termsOfService',
      href: toWWWLink('/terms-of-service'),
    },
    {
      key: 'footer_links_privacyPolicy',
      href: toWWWLink('/privacy-policy'),
    },
    {
      key: 'footer_links_membershipTerms',
      href: toWWWLink('/membership-terms'),
    },
  ],
};
