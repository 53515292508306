// eslint-disable-next-line no-restricted-imports
import { TLD } from '@peloton/app-config';
import { Country, toCountry } from './country';
import { Language, toLanguage } from './language';
import { isRouteSegmentLocale } from './path';
import { LOCALE_TOGGLE } from './toggle';

export enum Locale {
  EnglishUnitedStates = 'en-US',
  EnglishAustralia = 'en-AU',
  EnglishUnitedKingdom = 'en-GB',
  EnglishCanada = 'en-CA',
  GermanGermany = 'de-DE',
  GermanAustria = 'de-AT',
  Default = 'en-US',
  EnglishBeta = 'uz-UZ',
  SpanishMexico = 'es-MX',
  FrenchCanada = 'fr-CA',
}

// Types of locales for languages for class content may differ from Locale,
// which was introduced when display and content languages were assumed to be the same.
export enum ContentLocale {
  EnglishUnitedStates = 'en-US',
  EnglishAustralia = 'en-AU',
  EnglishUnitedKingdom = 'en-GB',
  EnglishCanada = 'en-CA',
  GermanGermany = 'de-DE',
  GermanAustria = 'de-AT',
  SpanishSpain = 'es-ES',
  EnglishBeta = 'uz-UZ',
  SpanishMexico = 'es-MX',
  FrenchCanada = 'fr-CA',
}

const requiresMarketingOptIn = [
  Locale.EnglishUnitedKingdom,
  Locale.EnglishCanada,
  Locale.EnglishAustralia,
  Locale.GermanGermany,
];

export const toAllowMarketing = (locale: Locale) =>
  !requiresMarketingOptIn.includes(locale);

export const DEFAULT_LOCALE_FOR_LANGUAGE: Partial<Record<Language, Locale>> = {
  [Language.English]: Locale.EnglishUnitedStates,
  [Language.German]: Locale.GermanGermany,
  [Language.Spanish]: Locale.SpanishMexico,
  [Language.French]: Locale.FrenchCanada,
};

export enum SupportedTLD {
  Au = '.com.au',
  Com = '.com',
  CoUk = '.co.uk',
  Ca = '.ca',
  De = '.de',
  At = '.at',
}

export enum SupportedSubpathLocale {
  Au = '.com/en-AU',
  Com = '.com',
  CoUk = '.com/en-GB',
  Ca = '.com/en-CA',
  De = '.com/de-DE',
  At = '.com/de-AT',
}

export const countryPathToLocaleMap: Record<Country | string, Locale> = {
  uk: Locale.EnglishUnitedKingdom,
  [Country.Canada]: Locale.EnglishCanada,
  [Country.Austria]: Locale.GermanAustria,
  [Country.Germany]: Locale.GermanGermany,
  [Country.Australia]: Locale.EnglishAustralia,
  [Country.UnitedStates]: Locale.EnglishUnitedStates,
};

export const localeToNetlifyCountries: Partial<
  Record<Locale, Array<Country | string>>
> = {
  [Locale.EnglishCanada]: [Country.Canada],
  [Locale.EnglishUnitedKingdom]: ['ie', Country.UnitedKingdom],
  [Locale.GermanGermany]: [Country.Germany],
  [Locale.EnglishAustralia]: [Country.Australia],
  [Locale.GermanAustria]: [Country.Austria],
};

export const localeTldMap: Record<Locale, SupportedTLD> = {
  [Locale.EnglishBeta]: SupportedTLD.Com,
  [Locale.EnglishUnitedStates]: SupportedTLD.Com,
  [Locale.EnglishUnitedKingdom]: SupportedTLD.CoUk,
  [Locale.EnglishCanada]: SupportedTLD.Ca,
  [Locale.GermanGermany]: SupportedTLD.De,
  [Locale.GermanAustria]: SupportedTLD.At,
  [Locale.EnglishAustralia]: SupportedTLD.Au,
  [Locale.SpanishMexico]: SupportedTLD.Com,
  [Locale.FrenchCanada]: SupportedTLD.Ca,
};

export const removeHostTld = (hostname: string) => {
  const supportedTlds = Object.values(SupportedTLD);
  let url = hostname;
  supportedTlds.forEach(tld => {
    url = url.replace(tld, '');
  });
  return url;
};

const getSupportedTldsRegex = () => {
  const supportedTlds = Object.values(SupportedTLD);
  const escapedTlds = supportedTlds.map(tld => tld.replace(/\./g, '\\.'));

  return new RegExp(`${escapedTlds.join('|')}$`);
};
const supportedTldsRegex = getSupportedTldsRegex();

const getSupportedLocalesInPathRegex = () => {
  const supportedLocales = Object.values(Locale);

  return new RegExp(`^/(${supportedLocales.join('|')})`);
};
const supportedLocalesInPathRegex = getSupportedLocalesInPathRegex();

const toSupportedLocaleInPathMatch = (localePath: string | undefined): string | null => {
  const supportedLocaleMatches = localePath?.match(supportedLocalesInPathRegex);
  return supportedLocaleMatches ? supportedLocaleMatches[1] : null;
};

export const getSubpathLocale = (href: string) => {
  const locales = Object.values(Locale);
  let matchedLocale = '';
  locales.forEach(locale => {
    if (href.match(locale as string)) {
      matchedLocale = ('/' + locale) as string;
    }
  });
  if (matchedLocale === '/en-US') {
    matchedLocale = '';
  }
  return matchedLocale;
};

const localeDisplayNameMap: Record<Locale, string> = {
  [Locale.EnglishCanada]: 'Canada',
  [Locale.EnglishUnitedKingdom]: 'United Kingdom',
  [Locale.EnglishUnitedStates]: 'United States',
  [Locale.EnglishAustralia]: 'Australia',
  [Locale.GermanGermany]: 'Deutschland',
  [Locale.GermanAustria]: 'Austria',
  [Locale.SpanishMexico]: 'Mexico',
  [Locale.EnglishBeta]: 'English - beta',
  [Locale.FrenchCanada]: 'French Canada',
};

const localeDisplayNameWithArticleMap: Record<Locale, string> = {
  [Locale.EnglishCanada]: 'Canada',
  [Locale.EnglishUnitedKingdom]: 'the United Kingdom',
  [Locale.EnglishUnitedStates]: 'the United States',
  [Locale.EnglishAustralia]: 'Australia',
  [Locale.GermanGermany]: 'Germany',
  [Locale.GermanAustria]: 'Austria',
  [Locale.SpanishMexico]: 'Mexico',
  [Locale.EnglishBeta]: 'English - beta',
  [Locale.FrenchCanada]: 'French Canada',
};

//Per w3c recommendation (https://www.w3.org/International/questions/qa-choosing-language-tags#langsubtag), start adding region subtags when we use the same language in more than one locale.
const ISOLangMap: Record<Locale, string> = {
  [Locale.EnglishCanada]: 'en-CA',
  [Locale.EnglishUnitedKingdom]: 'en-GB',
  [Locale.EnglishUnitedStates]: 'en-US',
  [Locale.EnglishAustralia]: 'en-AU',
  [Locale.GermanGermany]: 'de',
  [Locale.GermanAustria]: 'de-AT',
  [Locale.SpanishMexico]: 'es',
  [Locale.EnglishBeta]: 'uz',
  [Locale.FrenchCanada]: 'fr-CA',
};

export const toMatchingTld = (hostname: string): SupportedTLD => {
  const match = hostname.match(supportedTldsRegex);

  return match ? (match[0] as SupportedTLD) : SupportedTLD.Com;
};

export const toLocaleTld = (_: string): SupportedTLD => {
  const locale = toLocaleFromHostname();
  return localeTldMap[locale];
};

export const toExternalTld = (hostname: string): SupportedTLD => {
  return LOCALE_TOGGLE ? toLocaleTld(hostname) : toMatchingTld(hostname);
};

export const toLocale = (
  language: Language = toLanguage(),
  country: Country = toCountry(),
): Locale => {
  const locale = `${language}-${country.toUpperCase()}`;
  return toLocaleFromString(locale, language);
};

/**
 * This will return Locale based on App Config provided TLD, if available.
 * Falls back to `window.location.hostname`, if not.
 */
export const toLocaleFromHostname = (
  hostname: string = TLD ??
    (typeof window !== 'undefined' ? window.location.hostname : '.com'),
  isUsingPathnameLocale: boolean = false,
): Locale => {
  let value: Locale = Locale.Default;

  if (LOCALE_TOGGLE || isUsingPathnameLocale) {
    const firstRouteSegment =
      typeof window !== 'undefined' ? window?.location?.pathname?.split('/')[1] : '';
    if (firstRouteSegment && isRouteSegmentLocale(firstRouteSegment)) {
      return toLocaleFromString(firstRouteSegment);
    }
  }

  const assignLocaleValue = ([locale, tld]: [Locale, SupportedTLD]) => {
    if (
      hostname.endsWith(tld) &&
      locale != Locale.EnglishBeta &&
      locale !== Locale.SpanishMexico &&
      locale !== Locale.FrenchCanada
    ) {
      value = locale;
    }
  };

  // NextJS requires named functions to be used in components
  (Object.entries(localeTldMap) as [Locale, SupportedTLD][]).forEach(assignLocaleValue);

  return value;
};

export const toLocaleFromTLD = toLocaleFromHostname;

export const toLocaleFromPath = (path?: string) => {
  const localeMatch = toSupportedLocaleInPathMatch(
    path ?? (typeof window !== 'undefined' ? window.location.pathname : undefined),
  );
  return localeMatch ? toLocaleFromString(localeMatch) : undefined;
};

// TODO: remove dependency on window.location
export const toLocaleOrigin = (
  locale: Locale,
  location: Location = window.location,
  currentLocale?: Locale,
): string => {
  let currentTld = null;

  if (currentLocale !== undefined) {
    currentTld = localeTldMap[currentLocale];
  } else {
    currentTld = localeTldMap[toLocaleFromHostname(location.hostname)];
  }

  let toTld: any = localeTldMap[locale];

  if (locale === Locale.EnglishAustralia) {
    toTld = '.com.au';
  }

  const hostname = location.hostname.replace(currentTld, toTld);
  const port = location.port ? `:${location.port}` : '';

  // launch day hack to handle corporate wellness redirects so we don't lose leads
  // TODO: platform fix for handling pathname redirects globally
  let pathname = '';
  if (
    location.pathname === '/corporate-wellness' &&
    (locale === Locale.EnglishUnitedStates ||
      locale === Locale.EnglishCanada ||
      locale === Locale.EnglishUnitedKingdom)
  ) {
    pathname = `${location.pathname}${location.search}`;
  }

  // launch day hack to handle guide announcement redirects so we don't lose leads
  // TODO: platform fix for handling pathname redirects globally
  if (
    location.pathname === '/guide-announce' &&
    (locale === Locale.EnglishUnitedStates ||
      locale === Locale.EnglishCanada ||
      locale === Locale.EnglishUnitedKingdom ||
      locale === Locale.EnglishAustralia)
  ) {
    pathname = `${location.pathname}${location.search}`;
  }

  return `${location.protocol}//${hostname}${port}${pathname}`;
};

export const toLocaleHostname = (locale: Locale) =>
  `onepeloton${localeTldMap[locale] ?? localeTldMap[Locale.Default]}`;

export const toDisplayName = (locale: Locale): string => localeDisplayNameMap[locale];

export const toDisplayNameWithArticle = (locale: Locale): string =>
  localeDisplayNameWithArticleMap[locale];

export const toISOLang = (locale: Locale): string => ISOLangMap[locale];

export const toLocaleFromString = (locale: string, language?: Language): Locale => {
  let key;

  if (locale) {
    key = Object.keys(Locale).find(
      k => Locale[k as keyof typeof Locale].toLowerCase() === locale.toLowerCase(),
    );
  }

  return key
    ? Locale[key as keyof typeof Locale]
    : DEFAULT_LOCALE_FOR_LANGUAGE[language!] || Locale.Default;
};

export const toLocaleFromCountry = (country: Country) => {
  const locales = {
    gb: Locale.EnglishUnitedKingdom,
    de: Locale.GermanGermany,
    at: Locale.GermanAustria,
    au: Locale.EnglishAustralia,
    us: Locale.EnglishUnitedStates,
    ca: Locale.EnglishCanada,
  };

  return locales[country];
};

export const isLocale = (str?: string): str is Locale =>
  Object.values(Locale).includes(str as Locale);

export const toLocaleWithFallback = (locale: Locale) => {
  if (locale === Locale.GermanAustria) {
    return Locale.GermanGermany;
  }
  return locale;
};

export const newLocaleNoIndex = (locale: Locale, noIndex?: boolean) => {
  return noIndex || (locale !== Locale.EnglishUnitedStates && LOCALE_TOGGLE);
};
