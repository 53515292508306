import React from 'react';
import { nest } from 'recompose';
import styled from 'styled-components';
import { redirect } from '@peloton/navigation';
import useRequiresAuth from '@account/auth/useRequiresAuth';
import { DomainBasedCopyProvider, useAccountKeyValue } from '@account/copy';
import { HYATT_OFFER_CONNECT } from '@onewellness/routes';
import { PageWrapper } from '../shared';
import { Confirm } from './Confirm';
import { Connected } from './Connected';
import { Error } from './Error';
import { Loading } from './Loading';
import { useHyattConnect } from './useHyattConnect';

const Page = () => {
  useRequiresAuth();

  const {
    token,
    error,
    loading,
    connected,
    connecting,
    reset,
    register,
  } = useHyattConnect();
  const connectingHeadline = useAccountKeyValue('hyatt.offer.confirm.loading');

  let content;
  if (error) {
    content = <Error reset={reset} />;
  } else if (loading) {
    content = <Loading />;
  } else if (connected) {
    content = <Connected />;
  } else if (connecting) {
    content = <Loading headline={connectingHeadline} />;
  } else if (token) {
    content = <Confirm register={register} />;
  } else {
    redirect(HYATT_OFFER_CONNECT);
  }

  return (
    <PageWrapper>
      <Content>{content}</Content>
    </PageWrapper>
  );
};

const Content = styled.div`
  display: flex;
  padding: 4rem 1rem;
  flex-direction: column;
  align-items: center;
`;

export default nest(DomainBasedCopyProvider, Page);
