import { Container, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';

type Props = Omit<React.CSSProperties, 'translate'> & {
  children?: React.ReactNode;
};

const ContainerWithShadow: React.FC<Props> = ({ children, ...props }) => (
  <Container
    padding={{
      mobile: `${spacing[32]} ${spacing[24]}`,
      desktop: `${spacing[48]} ${spacing[64]}`,
    }}
    borderRadius={'8px'}
    hasShadow={true}
    width={'100%'}
    backgroundColor={white}
    {...props}
  >
    {children}
  </Container>
);

export default ContainerWithShadow;
