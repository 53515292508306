import { black, white } from '@pelotoncycle/design-system';
import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from '@peloton/react';
import { ie11, media } from '@peloton/styles';
import { useAccountKeyValue } from '@account/copy';
import { useHeaderReference } from '@acme-ui/global';
import { GenericLogo as Logo } from '@ecomm/header/Logo';
import AccountMenu from './AccountMenu';
import {
  HEADER_HEIGHT_DESKTOP_LARGE,
  HEADER_HEIGHT_MOBILE,
  HEADER_HEIGHT_TABLET_XLARGE,
  zIndex,
} from './constants';
import HeaderAvatar from './HeaderAvatar';
import { HeaderNavContext } from './HeaderNavProvider';
import MenuItem from './MenuItem';

export type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  transparent?: boolean;
};

type Props = OwnProps;

const HeaderNavView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  style = {},
  transparent = false,
}) => {
  const { isShowingMembershipMenu, setIsShowingMembershipMenu } = useContext(
    HeaderNavContext,
  );

  const headerRef = useHeaderReference();

  useOnClickOutside(headerRef, () => setIsShowingMembershipMenu(false), false);

  const ref = useRef(null);

  const logoLabel = useAccountKeyValue('header.homeLinkLabel');
  const menuTitle = useAccountKeyValue('account.nav.account');

  return (
    <Container
      ref={headerRef}
      className={className}
      transparent={transparent}
      style={style}
      data-element-id="header"
    >
      <Logo label={logoLabel} />
      <MenuItem
        text={menuTitle}
        testId="account"
        onClick={() => setIsShowingMembershipMenu(previous => !previous)}
        itemRef={ref}
        expanded={isShowingMembershipMenu}
      />
      <HeaderAvatar />
      {isShowingMembershipMenu ? <AccountMenu /> : null}
    </Container>
  );
};

const transparencyStyles = css`
  position: sticky;

  + * {
    margin-top: -${HEADER_HEIGHT_MOBILE}px;

    ${media.tabletXLarge`
      margin-top: -${HEADER_HEIGHT_TABLET_XLARGE}px;
    `}

    ${media.desktopLarge`
      margin-top: -${HEADER_HEIGHT_DESKTOP_LARGE}px;
    `}
  }

  ${ie11`
    position: relative;
  `}
`;

export const StyledMenuBar = styled.ul`
  margin: 0 auto;
  height: 56px;
  display: flex;
  align-items: center;
`;

export const ICON_SIDE_PADDING = '12px';

const Container = styled.nav<{ transparent: boolean }>`
  align-items: center;
  color: ${white};
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT_MOBILE}px;
  background: linear-gradient(to bottom, #191c20 23%, rgba(25, 28, 32, 0));
  position: relative;
  padding: 0 0 0 1rem;
  top: 0;
  width: 100%;
  z-index: ${zIndex.HEADER};

  ${media.tabletXLarge`
    height: 64px;
  `}

  ${media.tabletXLarge`
    height: ${HEADER_HEIGHT_TABLET_XLARGE}px;
    padding: 0 0 0 1.5rem;
  `}
  
  ${media.desktopLarge`
    height: ${HEADER_HEIGHT_DESKTOP_LARGE}px;
    padding: 0 0 0 2rem;
  `}
  
  svg {
    display: block;
    fill: currentColor;
  }

  div.focus-lock {
    display: flex;
    height: 100%;
    align-items: center;
  }

  ${props => props.transparent && transparencyStyles}

  @media print {
    background: transparent !important;

    svg {
      fill: ${black};
    }

    ${StyledMenuBar}
  }

  li {
    list-style: none;
  }
`;

export default HeaderNavView;
