import {
  Body,
  Flex,
  Link,
  LinkButton,
  Eyebrow,
  Label,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useSelector } from 'react-redux';
import { getExtLinkEnv, toDigitalLink, toHref } from '@peloton/external-links';
import { completeProfile } from '@peloton/links/digital';
import {
  checkIsProspectsUrl,
  generateMembersRedirectUrl,
  getStoredProspectsReferrerUrl,
} from '@peloton/members-urls/membersUrlUtils';
import { isMobile } from '@peloton/responsive';
import type { TrackingProperties } from '@account/analytics';
import { HappyPathEvents, useTrackCallback } from '@account/analytics';
import { AccountCopy, useAccountKeyValue } from '@account/copy';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';
import DownloadAppMobile from '@account/ui/components/downloadApp/DownloadAppMobile';
import { newUserCopy } from './copy';
import SocialProspectsDownloadAppDesktop from './SocialProspectsDownloadAppDesktop';

type Props = {
  href: string;
  track: ({ event }: TrackingProperties) => void;
  tierLabel?: string;
};

const MobileDownload: React.FC<React.PropsWithChildren<Props>> = ({ href, track }) => (
  <Flex flexDirection="column" alignItems="center" gap="24px">
    <DownloadAppMobile />
    <Link href={href} variant="body" onClick={() => track}>
      <AccountCopy id={newUserCopy.webProfileSetup} />
    </Link>
  </Flex>
);

const SocialProspectsMobileDownload: React.FC<React.PropsWithChildren<Props>> = ({
  href,
  track,
}) => (
  <Flex flexDirection="column" alignItems="center" gap="24px">
    <DownloadAppMobile deepLink={href} />
  </Flex>
);

const DownloadAppDesktop: React.FC<React.PropsWithChildren<Props>> = ({
  href,
  track,
  tierLabel,
}) => {
  const downloadAppQrCodeKey =
    tierLabel === 'Free'
      ? 'downloadAppQrCodeWithoutSubscription'
      : 'downloadAppQrCodeWithSubscription';

  const {
    image: downloadAppQRCodeImage,
    description: downloadAppQRCodeDescription,
  } = useAccountMetadata(downloadAppQrCodeKey);
  const {
    image: desktopMembersAppImage,
    description: desktopMembersAppImageDescription,
  } = useAccountMetadata('desktopMembersAppImage');

  const webSetupButtonCopy = useAccountKeyValue(newUserCopy.webProfileSetup);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={{
        tablet: `${spacing[16]} 0`,
        desktop: `${spacing[16]} 0`,
      }}
      centered
    >
      <Eyebrow size="small" style={{ marginBottom: 8 }}>
        <AccountCopy id={newUserCopy.getStartedHeadline} />
      </Eyebrow>
      <Body size="small" style={{ marginBottom: 80 }}>
        <AccountCopy id={newUserCopy.getStartedSubtitle} />
      </Body>
      <Flex flexDirection="row" justifyContent="center">
        <Flex flexDirection="column" gap="40px">
          <img
            height="160px"
            src={downloadAppQRCodeImage.url}
            alt={downloadAppQRCodeDescription}
            loading="lazy"
          />
          <Label size="large" textAlign={'center'}>
            <AccountCopy id={newUserCopy.mobileProfileSetup} />
          </Label>
        </Flex>
        <Body
          size="small"
          style={{
            marginTop: 68,
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          <AccountCopy id={newUserCopy.choice} />
        </Body>
        <Flex flexDirection="column" gap="40px">
          <img
            height="160px"
            src={desktopMembersAppImage.url}
            alt={desktopMembersAppImageDescription}
            loading="lazy"
          />
          <LinkButton
            href={href}
            onClick={() => track}
            size="medium"
            color="dark"
            variant="outline"
            width="adjustable"
            text={webSetupButtonCopy}
            style={{
              paddingRight: spacing[24],
              paddingLeft: spacing[24],
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const DownloadApp: React.FC<
  React.PropsWithChildren<{
    isGreaterThanDesktop: Boolean;
    tierLabel?: string;
    isSocialProspectsExperience?: boolean;
    socialProspectDeepLink?: string | null;
  }>
> = ({
  isGreaterThanDesktop = true,
  tierLabel,
  isSocialProspectsExperience,
  socialProspectDeepLink,
}) => {
  const extLinkEnv = useSelector(getExtLinkEnv);
  const completeProfileLink = toHref(completeProfile, extLinkEnv);
  const homeLink = toHref(toDigitalLink(''), extLinkEnv);
  const referrerUrl = getStoredProspectsReferrerUrl();
  const href =
    referrerUrl && checkIsProspectsUrl(referrerUrl)
      ? generateMembersRedirectUrl(homeLink, referrerUrl)
      : completeProfileLink;

  const { track } = useTrackCallback();

  const trackContinueToWeb = () =>
    track({
      event: HappyPathEvents.ContinueToWebClicked,
    });

  return (
    <div>
      {isGreaterThanDesktop ? (
        isSocialProspectsExperience && !isMobile() ? (
          <SocialProspectsDownloadAppDesktop />
        ) : (
          <DownloadAppDesktop
            href={href}
            track={trackContinueToWeb}
            tierLabel={tierLabel}
          />
        )
      ) : isSocialProspectsExperience ? (
        <SocialProspectsMobileDownload
          href={socialProspectDeepLink || href}
          track={trackContinueToWeb}
        />
      ) : (
        <MobileDownload
          href={isSocialProspectsExperience ? socialProspectDeepLink || '' : href}
          track={trackContinueToWeb}
        />
      )}
    </div>
  );
};

export default DownloadApp;
