export const PROSPECTIVE_PROSPECTS_URL_LOCAL_STORAGE_KEY = 'prospectsUrl';
const PROSPECTS_CLASS_DISCIPLINE_MATCHER = /(?:classes|prospects)\/[^/]+/;
const PELOTON_DOMAIN_MATCHER = /^(?:https:\/\/)?(?:[-\w]+\.)*onepeloton./;

export function checkIsProspectsUrl(url: string) {
  return PROSPECTS_CLASS_DISCIPLINE_MATCHER.test(url) && PELOTON_DOMAIN_MATCHER.test(url);
}

export function storeProspectsReferrerUrl(referrerUrl: string) {
  window.localStorage.setItem(PROSPECTIVE_PROSPECTS_URL_LOCAL_STORAGE_KEY, referrerUrl);
}

export function getStoredProspectsReferrerUrl() {
  return !!window
    ? (window.localStorage.getItem(PROSPECTIVE_PROSPECTS_URL_LOCAL_STORAGE_KEY) as
        | string
        | undefined)
    : '';
}

export function generateProspectsClassUrl(
  prospectsClassBaseUrl: string,
  rideId: string,
  queryString: string,
) {
  return `${prospectsClassBaseUrl}/${rideId}${queryString}`;
}

export function generateMembersClassUrl(
  baseUrl: string,
  classId?: string,
  discipline?: string,
) {
  const baseUrlAndPath = discipline
    ? generateMembersLibraryPageUrl(baseUrl, discipline)
    : `${baseUrl}/home`;

  return classId
    ? `${baseUrlAndPath}?modal=classDetailsModal&classId=${classId}`
    : `${baseUrlAndPath}`;
}

export function generateAccountUrl(baseUrl: string, classId?: string) {
  return classId ? `${baseUrl}?modal=classDetailsModal&classId=${classId}` : `${baseUrl}`;
}

export function generateMembersLibraryPageUrl(baseUrl: string, discipline?: string) {
  return discipline
    ? `${baseUrl}/classes/${toMembersDisciplineSlug(discipline)}`
    : `${baseUrl}/classes`;
}

export function generateMembersRedirectUrl(baseUrl: string, prospectsUrl?: string) {
  // check if url is of the prospects class discipline or class details structure
  if (prospectsUrl && checkIsProspectsUrl(prospectsUrl)) {
    const prospectsUrlObj = new URL(prospectsUrl);
    const queryParams = new URLSearchParams(prospectsUrlObj.search);
    const classId = queryParams.get('classId') ?? undefined;

    // a prospects url pathname split by '/' will always return ['', 'classes', 'discipline', 'slug'] (if there is a slug)
    const prospectsUrlComponents = prospectsUrlObj.pathname.split('/');
    const classDiscipline =
      prospectsUrlComponents.length > 2 ? prospectsUrlComponents[2] : '';
    const basePage = classDiscipline ? classDiscipline : 'home';

    return classId
      ? generateMembersClassUrl(baseUrl, classId, basePage)
      : generateMembersLibraryPageUrl(baseUrl, classDiscipline);
  }

  // default to whatever was passed in
  return baseUrl;
}

const toMembersDisciplineSlug = (disciplineSlug: string) => {
  switch (disciplineSlug) {
    case 'outdoor-workout':
      return 'outdoor';
    default:
      return disciplineSlug;
  }
};
