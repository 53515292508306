import React from 'react';
import styled from 'styled-components';
import type { CopyHook } from '@peloton/copy-generic';
import type { BreakpointOptions, Props as BTProps } from '@peloton/images';
import { BreakpointTransforms } from '@peloton/images';
import type { Options } from '@peloton/images-ui';
import type { Omit } from '@peloton/types';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

type Props = ImageProps &
  Omit<BTProps, 'isLocal' | 'render'> & {
    alt: string;
  };

const DEFAULT_TRANSFORMS: Partial<Options> = {
  crop: 'fill',
};

const isLocalEnv = (hostname = window.location.hostname) => /^local/.test(hostname);

const Image: React.FC<React.PropsWithChildren<Props>> = ({
  alt,
  src,
  breakpointOptions,
  ...imgProps
}) => (
  <BreakpointTransforms
    {...imgProps}
    breakpointOptions={withDefaultTransforms(breakpointOptions)}
    isLocal={isLocalEnv()}
    render={(transformProps, options) => (
      <StyledImage alt={alt} options={options} {...transformProps} />
    )}
    src={src}
  />
);

type LocalizedProps<T extends string> = Omit<Props, 'alt'> & {
  altTextId: T;
  useAltText: CopyHook<T>;
};
const LocalizedImage = <T extends string>({
  altTextId,
  useAltText,
  ...imageProps
}: LocalizedProps<T>) => <Image {...imageProps} alt={useAltText(altTextId)} />;

export { Image, LocalizedImage };

// this handles cases for retina screens where the [DPR](https://docs.fastly.com/api/imageopto/dpr) is 2x normal screens
// therefore a higher res image comes back but should still be represented by the same number of logical pixels
// also, we set height: 100% to handle cases where img are children of a flex box and did not properly
// scale their height
const StyledImage = styled(
  // eslint-disable-next-line jsx-a11y/alt-text
  ({ options: _, ...props }: ImageProps & { options: Options }) => <img {...props} />,
)`
  object-fit: contain;
  ${({ options: { width } }) => (width ? `width: ${width}px` : '')};
  ${({ options: { height } }) => (height ? `height: ${height}px` : 'height: 100%')};
`;

const withDefaultTransforms = (bpOptions: BreakpointOptions): BreakpointOptions =>
  Object.keys(bpOptions).reduce(
    defaultTransformReducer(bpOptions),
    {} as BreakpointOptions,
  );

const defaultTransformReducer = (opts: BreakpointOptions) => (
  newOpts: BreakpointOptions,
  key: keyof BreakpointOptions,
): BreakpointOptions => ({
  ...newOpts,
  [key]: {
    ...DEFAULT_TRANSFORMS,
    ...opts[key],
  },
});
