import React from 'react';
import styled from 'styled-components';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';
import { AppleBadgeLink, GooglePlayBadgeLink } from './AppStoreLinkBadges';
import useMobilePlatform from './useMobilePlatform';

type Props = {
  deepLink?: string;
};

const DownloadAppMobile: React.FC<React.PropsWithChildren<Props>> = ({ deepLink }) => {
  const { isiOS, isAndroid } = useMobilePlatform();

  if (deepLink) {
    return (
      <DownloadAppMobileContent data-test-id="socialProspectsDownloadAppMobileV2">
        <PelotonIcon />
        {isAndroid && <GooglePlayBadgeLink deepLink={deepLink} />}
      </DownloadAppMobileContent>
    );
  } else {
    return (
      <DownloadAppMobileContent data-test-id="downloadAppMobileV2">
        <PelotonIcon />
        {isiOS && <AppleBadgeLink />}
        {isAndroid && <GooglePlayBadgeLink />}
      </DownloadAppMobileContent>
    );
  }
};
export default DownloadAppMobile;

export const PelotonIcon = () => {
  const { image, description } = useAccountMetadata('pelotonAppIcon');

  return (
    <PelotonAppIcon
      data-test-id="pelotonAppIcon"
      src={image.url}
      alt={description}
      loading="lazy"
    />
  );
};

const DownloadAppMobileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    height: 56px;
  }
`;

const PelotonAppIcon = styled.img`
  height: 80px !important;
  border-radius: 4px;
  margin-top: 56px;
  margin-bottom: 48px;
`;
