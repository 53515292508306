import { useParams } from 'react-router-dom';
import {
  LULULEMON,
  LULULEMON_APP_ONE_SLUG,
  LULULEMON_APP_PLUS_SLUG,
  HYATT,
  HYATT_APP_ONE_SLUG,
  HYATT_APP_PLUS_SLUG,
} from '@account/subscription-mobility/slugs';

type PartnerPromoCode =
  | 'LULULEMONAPPONE2024'
  | 'LULULEMONAPPPLUS2024'
  | 'HYATTAPPONE2024'
  | 'HYATTAPPPLUS2024';

export const promosByPartner = {
  [LULULEMON]: {
    [LULULEMON_APP_ONE_SLUG]: 'LULULEMONAPPONE2024',
    [LULULEMON_APP_PLUS_SLUG]: 'LULULEMONAPPPLUS2024',
  },
  [HYATT]: {
    [HYATT_APP_ONE_SLUG]: 'HYATTAPPONE2024',
    [HYATT_APP_PLUS_SLUG]: 'HYATTAPPPLUS2024',
  },
};

const useTierPromoForPartner = (): PartnerPromoCode | undefined => {
  const { slug, promoPartner } = useParams<{ slug: string; promoPartner: string }>();

  return promosByPartner?.[promoPartner]?.[slug];
};

export const useIsLululemonPromo = () => {
  const { promoPartner } = useParams<{ promoPartner: string }>();
  const promo = useTierPromoForPartner();

  return promo && promoPartner === LULULEMON;
};

export const useIsHyattPromo = () => {
  const { promoPartner } = useParams<{ promoPartner: string }>();
  const promo = useTierPromoForPartner();

  return promo && promoPartner === HYATT;
};

export default useTierPromoForPartner;
