import { brand, grey, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { getTLD } from '@peloton/external-links';
import { media } from '@peloton/styles';
import { useLogout } from '@account/auth';
import { useAccountKeyValue } from '@account/copy';
import { getBenefitHubPaymentMethod } from '@account/pg-my-membership/redux';
import { hb6 } from '@acme-ui/typography';
import type { TrackedNavLinkProps } from '@ecomm/header/TrackedNavLink';
import TrackedNavLink from '@ecomm/header/TrackedNavLink';
import { Menu } from '@ecomm/header/UtilitiesMenu/PopupMenu';
import { useReduxState } from '@engage/redux';
import { MENU_WIDTH, MENU_LINK_CONTENT } from './constants';

type LinkCopy = {
  href: string;
  text: string;
};

const useMenuCopy = (): LinkCopy[] => {
  const benefitHubPaymentMethod = useReduxState(getBenefitHubPaymentMethod);
  const showBenefitCenter = Boolean(benefitHubPaymentMethod);

  const tld = useReduxState(getTLD);

  const benefitsHref = useAccountKeyValue('account.nav.account.benefitCenter.href', {
    tld,
  });
  const benefitsText = useAccountKeyValue('account.nav.account.benefitCenter.text', {
    tld,
  });

  const benefitCenterCopy: LinkCopy[] = [];

  if (showBenefitCenter) {
    benefitCenterCopy.push({ href: benefitsHref, text: benefitsText });
  }

  const linkCopyContent = MENU_LINK_CONTENT.map(({ hrefKey, textKey }) => ({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    href: useAccountKeyValue(hrefKey, { tld }),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    text: useAccountKeyValue(textKey),
  }));

  return benefitCenterCopy.concat(linkCopyContent);
};

const AccountMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const logout = useLogout();
  const logoutKey = 'account.nav.account.logout';
  const logoutCopy = useAccountKeyValue(logoutKey);

  const menuCopy = useMenuCopy();

  return (
    <StyledMenu data-test-id={'accountCardOpen'}>
      <ul aria-labelledby="accountMenuItem" role="menu">
        {menuCopy.map(({ text, href }) => (
          <li key={text}>
            <StyledLink
              to={href}
              trackingProps={{
                category: 'account.nav.account',
                linkName: text,
                linkTo: href,
              }}
              role="menuitem"
            >
              {text}
            </StyledLink>
          </li>
        ))}
        <SpacerLI />
        <li>
          <StyledLink
            data-test-id={logoutKey}
            onClick={() => logout()}
            trackingProps={{
              category: 'account.nav.account',
              unitName: logoutKey,
              linkName: logoutCopy,
            }}
          >
            {logoutCopy}
          </StyledLink>
        </li>
      </ul>
    </StyledMenu>
  );
};

export default AccountMenu;

const StyledMenu = styled(Menu)`
  width: ${MENU_WIDTH}px;
  right: 6px;
  background-color: ${white};
  border-radius: 3px;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  white-space: nowrap;

  li:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  ${media.tablet`
    right: 12px;
  `}

  ${media.desktop`
    right: 39px;
  `}
`;

const StyledLink = (styled(TrackedNavLink)`
  ${hb6};
  color: ${brand.darkest};
  &:hover {
    color: ${grey[70]};
    cursor: pointer;
  }
` as any) as React.ComponentType<React.PropsWithChildren<TrackedNavLinkProps>>;

const SpacerLI = styled.li`
  list-style-position: inside;
  width: 100%;
  border-bottom: 1px solid ${grey[40]};
`;
