import type { PeloLink } from '@peloton/external-links';
import { generateAppsFlyerLink } from './helpers';

interface TeamDeepLinkProps {
  teamId: string;
  teamName: string;
  inviterName: string;
  inviterImageUrl: string;
  teamsLink: string | PeloLink;
  logoUrl?: string;
  appleRedemptionCodeUrl?: string;
}

interface ChallengeDeepLinkProps {
  teamId: string;
  challengeId: string;
  teamName: string;
  inviterName: string;
  inviterImageUrl: string;
  challengeName: string;
  teamsLink: string | PeloLink;
  logoUrl?: string;
  appleRedemptionCodeUrl?: string;
}

const toAppsFlyerIosUrl = (appleRedemptionCodeUrl: string | undefined) => {
  if (appleRedemptionCodeUrl) {
    return [
      {
        paramKey: 'af_ios_url',
        defaultValue: encodeURIComponent(appleRedemptionCodeUrl),
      },
    ];
  } else {
    return undefined;
  }
};

export const generateTeamDeepLink = ({
  teamId,
  teamName,
  inviterName,
  inviterImageUrl,
  teamsLink,
  logoUrl,
  appleRedemptionCodeUrl,
}: TeamDeepLinkProps) => {
  const deepLinkValue =
    `${teamsLink}${teamId}/request_to_join?` +
    `inviter_name=${inviterName}&inviter_image_url=${inviterImageUrl}&team_name=${teamName}`;

  if (appleRedemptionCodeUrl !== '') {
    return generateAppsFlyerLink({
      deepLinkValue,
      logoUrl,
      customParams: toAppsFlyerIosUrl(appleRedemptionCodeUrl),
    });
  }

  return generateAppsFlyerLink({ deepLinkValue, logoUrl });
};

export const generateChallengeDeepLink = ({
  teamId,
  challengeId,
  teamName,
  inviterName,
  inviterImageUrl,
  challengeName,
  teamsLink,
  logoUrl,
  appleRedemptionCodeUrl,
}: ChallengeDeepLinkProps) => {
  const deepLinkValue =
    `${teamsLink}${teamId}/challenges/${challengeId}/request_to_join?` +
    `inviter_name=${inviterName}&inviter_image_url=${inviterImageUrl}&team_name=${teamName}&challenge_name=${challengeName}`;

  if (appleRedemptionCodeUrl !== '') {
    return generateAppsFlyerLink({
      deepLinkValue,
      logoUrl,
      customParams: toAppsFlyerIosUrl(appleRedemptionCodeUrl),
    });
  }

  return generateAppsFlyerLink({ deepLinkValue, logoUrl });
};
