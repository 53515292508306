import type { FullWidthTextProps } from '@pelotoncycle/design-system';
import { spacing, FullWidthText } from '@pelotoncycle/design-system';
import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

type Props = { width?: CSSProperties['width'] } & Pick<
  FullWidthTextProps,
  'headlineText' | 'bodyText' | 'cta'
>;

export const Body: React.FC<Props> = ({ width, headlineText, bodyText, cta }) => {
  return (
    <FullWidthTextStyled
      width={width}
      backgroundColor="none"
      headlineText={headlineText}
      bodyText={bodyText}
      theme="dark"
      cta={{
        variant: 'solid',
        color: 'primary',
        size: 'large',
        ...cta,
      }}
    />
  );
};

const FullWidthTextStyled = styled(FullWidthText)`
  max-width: ${(props: Props) => (props?.width ? props.width : 'auto')};

  & > section {
    padding: ${spacing[32]} 0;
  }
`;
