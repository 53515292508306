import React from 'react';
import { withProps, compose } from 'recompose';
import { Header as AccountHeader } from '@account/header';
import { PageFooter as AccountFooter } from '../../ui';
import PageContainer from '../PageContainer';

const AccountPageLayout = compose(
  withProps({
    headerComponent: <AccountHeader hasHelpBlock={true} />,
    footerComponent: <AccountFooter />,
  }),
)(PageContainer);

export default AccountPageLayout;
