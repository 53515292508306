import { brand, grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import gfm from 'remark-gfm';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { withExtHrefs } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { FaqView } from '@ecomm/faqs';
import type { LinkProps } from '@ecomm/links';
import { Link, inlineLinkStyles } from '@ecomm/links';
import { b4 } from '@ecomm/typography';

type FaqProps = {
  id: string;
  question: React.ReactNode;
  answer: string;
};

type Props = {
  className?: string;
  appPageHref: string;
  homepageHref: string;
  hrmHref: string;
  membersLink: string;
  recommendedBikeHeadphonesHref: string;
  recommendedTreadHeadphonesHref: string;
  registrationHref: string;
  subscriptionPreferencesHref: string;
  supportHref: string;
  accountSharingSupportLink: string;
  supportRequestLink: string;
  studioLink: string;
  returnPolicyLink: string;
  ukFinancingDisclosure: string;
  values?: { [key: string]: React.ReactNode };
  onOpenStateChanged?: (state: boolean) => void;
};

const BaseFaq: React.FC<React.PropsWithChildren<Props & FaqProps>> = ({
  answer,
  id,
  appPageHref,
  homepageHref,
  membersLink,
  hrmHref,
  question,
  recommendedBikeHeadphonesHref,
  recommendedTreadHeadphonesHref,
  registrationHref,
  subscriptionPreferencesHref,
  supportHref,
  supportRequestLink,
  studioLink,
  accountSharingSupportLink,
  returnPolicyLink,
  ukFinancingDisclosure,
  values,
  onOpenStateChanged,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpenStateChanged = React.useCallback(
    (openState: boolean) => {
      setIsOpen(openState);
      onOpenStateChanged?.(openState);
    },
    [onOpenStateChanged],
  );

  return (
    <FaqView
      id={id}
      question={question}
      answer={
        <Markdown
          content={answer}
          values={{
            digitalPageLink: appPageHref,
            homepageLink: homepageHref,
            hrmLink: hrmHref,
            membersLink,
            recommendedBikeHeadphonesLink: recommendedBikeHeadphonesHref,
            recommendedTreadHeadphonesLink: recommendedTreadHeadphonesHref,
            registrationHref,
            subscriptionPreferencesHref,
            supportLink: supportHref,
            chatLink: `?chat=true`,
            studioLink,
            accountSharingSupportLink,
            supportRequestLink,
            returnPolicyLink,
            ukFinancingDisclosure,
            ...values,
          }}
          markdown={{
            plugins: [gfm],
            renderers: {
              link: toMarkDownLink(isOpen),
              table: ScrolledTable,
            },
          }}
        />
      }
      propagateOpenState={handleOpenStateChanged}
    />
  );
};

const toMarkDownLink = (isOpen: boolean) =>
  function markdownLink({ href, ...props }: any) {
    return (
      <StyledLink
        {...(href.includes('http') ? { target: '_blank', rel: 'noopener' } : {})}
        tabIndex={isOpen ? 0 : -1}
        onClick={(e: React.MouseEvent) =>
          e.stopPropagation()
        } /* don't close this Faq when clicking a link */
        to={href}
        {...props}
      />
    );
  };

const LocalizedFaq = BaseFaq;

const FaqWithLinks = withExtHrefs({
  appPageHref: Links.app,
  homepageHref: Links.peloton,
  recommendedBikeHeadphonesHref: Links.recommendedBikeHeadphones,
  recommendedTreadHeadphonesHref: Links.recommendedTreadHeadphones,
  registrationHref: Links.register,
  hrmHref: Links.treadAccessories,
  supportHref: Links.support,
  accountSharingSupportLink: Links.accountSharingSupport,
  studioLink: Links.oldStudioHome,
  subscriptionPreferencesHref: Links.subscriptionPreferences,
  supportRequestLink: Links.requests,
  membersLink: Links.digitalHome,
  returnPolicyLink: Links.returns,
  ukFinancingDisclosure: Links.ukFinancingDisclosure,
})(LocalizedFaq);

const StyledLink = (styled(Link)`
  ${inlineLinkStyles}
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const Table = styled.table`
  ${b4}
  color: ${brand.darkest};
  margin-top: 50px;
  max-width: 840px;
  min-width: 688px;
  width: 100%;

  tbody {
    tr {
      line-height: 40px;

      :nth-child(2n + 1) {
        background-color: ${transparentize(0.36, grey[40])};
      }
    }
  }

  th {
    min-width: 100px;
    padding: 20px 0;
    text-align: left;
  }

  td {
    padding-right: 40px;

    :nth-child(1) {
      padding: 0 20px;
    }
  }
`;

const ScrollDiv = styled.div`
  overflow-x: scroll;
  width: 100%;

  ${media.desktop`
    ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
    }
  `}
`;

const ScrolledTable: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLTableElement>>
> = props => (
  <ScrollDiv>
    <Table {...props} />
  </ScrollDiv>
);

export default FaqWithLinks;
