import type { Product } from '@membership/ui/ComparisonTable/types';

export const toDigitalProduct = (tierLabel?: string) => {
  switch (tierLabel) {
    case 'digital_basic':
      return 'App One';

    case 'digital_plus':
      return 'App Plus';

    default:
      return 'Free Account';
  }
};

export const removeFreeTiers = (tierProducts: any) =>
  tierProducts.filter((product: Product) => product.level !== 0);
