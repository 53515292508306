import { BillingFrequency } from '@membership/models/Subscription';
import React from 'react';
import { Route } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { DomainBasedCopyProvider } from '@account/copy';
import { AccountPageLayout, PageBackground } from '@account/membership/layout';
import NewUserConfirmation from '@account/pg-membership-checkout/components/confirmation/NewUserConfirmation';
import { Route as AccountRoute } from '@account/router';
import { isEligibleForAppCheckout } from '@account/subscription-mobility/guards';
import { APP_MEMBERSHIP_CHECKOUT_ROUTE, DOWNLOAD_APP_ROUTE } from './urls';

const Routes = isEligibleForAppCheckout()
  ? [
      <AccountRoute
        key={APP_MEMBERSHIP_CHECKOUT_ROUTE}
        path={APP_MEMBERSHIP_CHECKOUT_ROUTE}
        component={asyncComponent(
          () => import('./Page' /* webpackChunkName: "AppMembershipCheckoutPage" */),
        )}
      />,
      <Route
        key={DOWNLOAD_APP_ROUTE}
        path={DOWNLOAD_APP_ROUTE}
        exact
        render={() => (
          <DomainBasedCopyProvider key={DOWNLOAD_APP_ROUTE}>
            <AccountPageLayout>
              <PageBackground>
                <NewUserConfirmation
                  billingFrequency={BillingFrequency.Monthly}
                  tierLabel={'Free'}
                />
              </PageBackground>
            </AccountPageLayout>
          </DomainBasedCopyProvider>
        )}
      />,
    ]
  : [];

export default Routes;
