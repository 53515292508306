import { useFeature } from '@optimizely/react-sdk';
import {
  Container,
  Flex,
  Headline,
  Support,
  Body,
  Link,
  spacing,
  TwoColumnLayout,
} from '@pelotoncycle/design-system';
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getExtLinkEnv, toAccountLink, toHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { isMobile } from '@peloton/responsive';
import { useSocialProspects, generateSocialDeepLink } from '@peloton/social-prospects';
import { CheckoutEvent, useTrackCallback } from '@account/analytics';
import { TierCheckoutType } from '@account/analytics/hooks/useTrackCompleteCheckoutStep';
import { AccountCopy } from '@account/copy';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';
import { FEATURES } from '@account/optimizely';
import { BillingPartner } from '@account/order/PaymentDetails/types';
import { toDigitalProduct } from '@account/pg-membership-checkout/AppCheckout/utilities';
import { useIsLululemonPromo } from '@account/pg-membership-checkout/hooks/useTierPromoForPartner';
import { toOrderDetailPath } from '@account/pg-order-history/urls';
import { isBreakpointGreaterThanSelector } from '@account/styles/breakpoints';
import ConfirmationIcon from '@account/subscription-mobility/components/confirmation/ConfirmationIcon';
import ContainerWithShadow from '@account/subscription-mobility/components/ui/ContainerWithShadow';
import type { BillingFrequency } from '@account/subscription-mobility/models';
import { getAppliedGiftCardsList } from '@ecomm/quick-checkout/redux/giftCards';
import { newUserCopy } from './copy';
import DownloadApp from './DownloadApp';

const NewUserConfirmation: React.FC<
  React.PropsWithChildren<{
    billingFrequency: BillingFrequency;
    tierLabel: string;
    orderId?: string | number;
  }>
> = ({ billingFrequency, tierLabel, orderId }) => {
  const isGreaterThanDesktop = useSelector(isBreakpointGreaterThanSelector('desktop'));
  const extLinkEnv = useSelector(getExtLinkEnv);
  const giftCardsList = useSelector(getAppliedGiftCardsList);
  const { promoPartner } = useParams<{ promoPartner: string }>();
  const isLululemonPromo = useIsLululemonPromo();

  const instanceProperties = {
    category: TierCheckoutType.DIGITAL,
    promotion: new URLSearchParams(location.search).get('promo') ?? '',
    promoPartner: promoPartner || '',
    billingCycle: billingFrequency || 'NA',
    digitalProduct: toDigitalProduct(tierLabel) || '',
    subscriptionType: 'Free Trial',
    products: [],
    skus: '',
    billingPartner: !!giftCardsList.length
      ? [BillingPartner.Stripe, BillingPartner.Fiserv]
      : BillingPartner.Stripe,
  };

  const { track } = useTrackCallback();

  useEffect(() => {
    track({
      event: CheckoutEvent.StartedDigitalMembership,
      instanceProperties,
    });
  }, []);

  const [isSocialProspectsExperienceEnabled] = useFeature(
    FEATURES.isSocialProspectsExperienceEnabled,
  );
  const { inviteData, isSocialProspectsExperience, source } = useSocialProspects(
    isSocialProspectsExperienceEnabled,
  );
  const [socialProspectDeepLink, setSocialPropsectDeepLink] = useState<string | null>(
    null,
  );
  const { image: pelotonLogo } = useAccountMetadata('pelotonAppIcon');

  useEffect(() => {
    if (!isSocialProspectsExperience) {
      return;
    }
    // generate link to redirect to social prospects
    const teamUrlPath = toHref(Links.teams, extLinkEnv);
    const deepLink = generateSocialDeepLink(
      source,
      inviteData,
      teamUrlPath,
      '',
      isGreaterThanDesktop ? pelotonLogo.url : undefined,
    );
    setSocialPropsectDeepLink(deepLink || '');
  }, [
    extLinkEnv,
    inviteData,
    isSocialProspectsExperience,
    source,
    pelotonLogo,
    isGreaterThanDesktop,
  ]);
  return (
    <Suspense fallback={null}>
      <TwoColumnLayout
        firstChildWidthPercent="45%"
        variant={'contained'}
        gap={
          isSocialProspectsExperience && isGreaterThanDesktop && !isMobile()
            ? '86px'
            : 'unset'
        }
      >
        <Container
          maxWidth="550px"
          centered
          horizontalPadding={spacing[16]}
          verticalPadding={{ mobile: spacing[48], desktop: spacing[80] }}
        >
          <Flex
            flexDirection="column"
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            textAlign={{ mobile: 'center', desktop: 'left' }}
          >
            {!isSocialProspectsExperience && (
              <ConfirmationIcon alignItemsDesktop="flex-start" />
            )}
            <Headline size="small" style={{ marginBottom: spacing[24] }}>
              <AccountCopy
                id={
                  isSocialProspectsExperience
                    ? newUserCopy.newUserSocialHeadline
                    : newUserCopy.headline
                }
                values={{
                  teamName: inviteData?.teamInfo?.name || inviteData?.challengeInfo?.name,
                }}
              />
            </Headline>
            {!isSocialProspectsExperience && (
              <Support size="large" style={{ marginBottom: spacing[24] }}>
                <AccountCopy
                  id={isLululemonPromo ? newUserCopy.lululemonBody : newUserCopy.body}
                />
              </Support>
            )}
            {isSocialProspectsExperience && (
              <Body size="small" style={{ marginBottom: spacing[24] }}>
                <AccountCopy id={newUserCopy.newUserSocialBody} />
              </Body>
            )}
            {orderId && (
              <Link
                size="medium"
                variant="body"
                href={toHref(
                  toAccountLink(toOrderDetailPath(orderId.toString())),
                  extLinkEnv,
                )}
              >
                <AccountCopy id={newUserCopy.receiptLink} />
              </Link>
            )}
          </Flex>
        </Container>
        <ContainerWithShadow
          maxWidth={`${isSocialProspectsExperience ? '624px' : 'unset'}`}
          margin={'0 auto'}
        >
          <DownloadApp
            isGreaterThanDesktop={isGreaterThanDesktop}
            tierLabel={tierLabel}
            isSocialProspectsExperience={isSocialProspectsExperience}
            socialProspectDeepLink={socialProspectDeepLink}
          />
        </ContainerWithShadow>
      </TwoColumnLayout>
    </Suspense>
  );
};

export default NewUserConfirmation;
