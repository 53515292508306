import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';

export const useHyattFaqCopy = () => {
  const faqs = [
    useAccountMetadata('onewellness.hyatt.connect.faqs.1'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.2'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.3'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.4'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.5'),
  ];

  return faqs.map((copy, i) => {
    return {
      key: copy.keywords[0] + i,
      question: copy.title,
      answer: copy.description,
    };
  });
};
